import { toastService } from "src/libraries/toast";
import { CreateResourceDto } from "../entity/resources/dto/create-resource-dto";
import { resourcesService } from "../entity/resources/ResourcesService";

export const createResourceUseCase = async (link: string, entityId: number, body: CreateResourceDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'file' && body?.file) {
                formData.append('file', body.file, body.file.name)
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await resourcesService.create(link, entityId, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
        }
        return response;
    } catch (error) {
        console.warn('createResourceUseCase: ', error);
    }
}  