import { useMemo } from "react";
import { issuesModel } from "../entity/issues/IssuesModel";
import { IIssue } from "../entity/issues/IIssue";

export const useIssuesStatistics = () => {
    const issues = issuesModel.all;

    const { reopened, reopenedPercentage } = useMemo(() => {
        const reopened = issues?.reduce((acc, item) => {
            if (item.reopend) acc += item.reopend;
            return acc;
        }, 0) || 0;
        const reopenedPercentage = (issues && reopened) ? (reopened / issues?.length) * 100 : 0;
        return { reopened, reopenedPercentage };
    }, [issues]);

    const { notEstimated, notEstimatedPercentage } = useMemo(() => {
        const notEstimated = issues?.reduce((acc, item) => {
            if (!item.timeoriginalestimate) acc += 1;
            return acc;
        }, 0);
        const notEstimatedPercentage = (issues && notEstimated) ? (notEstimated / issues?.length) * 100 : 0;
        return { notEstimated, notEstimatedPercentage };
    }, [issues]);

    const { estimationPercentage, biggestEstimationDiscrepancy, biggestAggregatetimespen } = useMemo(() => {
        const biggestAggregatetimespen = issues?.reduce((acc: IIssue, item) => {
            if (Number(item.aggregatetimespent) > Number(acc?.aggregatetimespent)) acc = item;
            return acc;
        }, { aggregatetimespent: 0 } as any);
        const biggestEstimationDiscrepancy = issues?.reduce((acc, item) => {
            if (item.timeoriginalestimate && item.aggregatetimespent) {
                const discrepancy = Number(item.aggregatetimespent) - Number(item.timeoriginalestimate);
                if (discrepancy > acc) acc = Number(discrepancy.toFixed(2));
            }
            return acc;
        }, 0);

        let estimationPercentage = 0;
        let count = 0;
        issues?.forEach((item) => {
            if (item.timeoriginalestimate && item.aggregatetimespent && Number(item.timeoriginalestimate) > 0 && Number(item.aggregatetimespent) > 0) {
                
                const estimatedMinutes = convertHoursToMinutes(item.timeoriginalestimate);
                const spentMinutes = convertHoursToMinutes(item.aggregatetimespent);
                const estimateDiffPercentage = (spentMinutes / estimatedMinutes) * 100;
                const absoluteDiff = Math.abs(estimateDiffPercentage - 100);
                console.log('absoluteDiff ---> ', absoluteDiff);
                estimationPercentage += absoluteDiff;
                count++;
            }
        });
        console.log(estimationPercentage, count);
        estimationPercentage = 100 - (estimationPercentage / count);
        return { estimationPercentage, biggestEstimationDiscrepancy, biggestAggregatetimespen };
    }, [issues]);

    const total = issues?.length || 0;

    return {
        reopened, reopenedPercentage,
        notEstimated, notEstimatedPercentage,
        estimationPercentage, biggestEstimationDiscrepancy, biggestAggregatetimespen,
        total
    };
}

const convertHoursToMinutes = (time: string) => {
    const [hours, minutes] = time.split('.');
    return Number(hours) * 60 + Number(minutes);
}