import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { registrationUseCase } from "../useCases/registrationUseCase";
import { config } from "src/Config";

export const useRegistration = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isLastNameError, setIsLastNameError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [isPasswordConfirmationError, setIsPasswordConfirmationError] = useState(false);
    const navigate = useNavigate();

    const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();
        setLastName(event.target.value);
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();
        setEmail(event.target.value);
    };

    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();
        setPassword(event.target.value);
    };

    const onPasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearErrors();
        setPasswordConfirmation(event.target.value);
    };

    const onPasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const onPasswordConfirmationVisibility = () => {
        setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
    };

    const clearErrors = () => {
        setIsFirstNameError(false);
        setIsLastNameError(false);
        setIsEmailError(false);
        setIsPasswordError(false);
        setIsPasswordConfirmationError(false);
    };

    const isFieldsValid = () => {
        let isValid = true;
        if (firstName === '') {
            setIsFirstNameError(true);
            isValid = false;
        }
        if (lastName === '') {
            setIsLastNameError(true);
            isValid = false;
        }
        if (!config.emailRegex.test(email)) {
            setIsEmailError(true);
            isValid = false;
        }
        if (password === '') {
            setIsPasswordError(true);
            isValid = false;
        }
        if (password !== passwordConfirmation) {
            setIsPasswordConfirmationError(true);
            isValid = false;
        }
        return isValid;
    };

    const onSubmit = async () => {
        const isValid = isFieldsValid();
        if (!isValid) {
            return;
        }
        setIsLoading(true);
        const result = await registrationUseCase({ email, password, firstName, lastName } as any);
        setIsLoading(false);
        if (result?.isError) {

        } else {
            navigate('/projects-list', { replace: true });
        }
    };

    return {
        isFirstNameError,
        isLastNameError,
        isEmailError,
        isPasswordError,
        isPasswordConfirmationError,
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation,
        isLoading,
        isPasswordVisible,
        isPasswordConfirmationVisible,
        onFirstNameChange,
        onLastNameChange,
        onEmailChange,
        onPasswordChange,
        onPasswordConfirmationChange,
        onPasswordVisibility,
        onPasswordConfirmationVisibility,
        onSubmit
    };
};