import { IIssue } from "./IIssue";
import { MobXModel } from "src/repository/MobXModel";
import { IIssueFilters } from "./IIssueFilters";
import { MobXRepository } from "src/repository/MobXRepository";

class CategoriesModel extends MobXModel<IIssue> {
    private filtersRepository = new MobXRepository<IIssueFilters>({ projects: [], assignees: [] });

    get filters() {
        return this.filtersRepository.data ?? { projects: [], assignees: [] };
    }

    set filters(data: IIssueFilters) {
        this.filtersRepository.save(data);
    }

}

export const issuesModel = new CategoriesModel();
