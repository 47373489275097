import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

interface IProps {
    title?: string;
    buttonTitle?: string;
    onPress?: () => void;
}

export const DashboardHeader: FC<IProps> = ({ title, buttonTitle, onPress }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>{title}</Typography>
            {onPress ? <Button variant="contained" onClick={onPress}>
                {buttonTitle}
            </Button> : null}
        </Stack>
    )
}
