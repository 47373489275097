import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getVacanciesUseCase } from "../useCases/getVacanciesUseCase";

export const useVacancies = () => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     getVacanciesUseCase();
    // }, []);

    const onGoToCreateEntity = useCallback(() => {
        navigate('/dashboard/vacancies/create');
    }, []);

    return { onGoToCreateEntity };
}
