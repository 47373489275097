export interface ILinks {
    readonly signIn: string;
    readonly signUp: string;
    readonly users: string;
    readonly categories: string;
    readonly products: string;
    readonly nltdevskeletons: string;
    readonly news: string;
    readonly newsCategory: string;
    readonly features: string;
    readonly vacancies: string;
    readonly candidates: string;
    readonly newsClient: string;
    readonly newsCategoryClient: string;
    readonly issues: string;
}

class Links implements ILinks {
    private dev = 'https://nmau.pp.ua/api';
    private _domain = this.dev;
    private _links = {
        readFiles: `${this._domain}/read-files`,

        signIn: `${this._domain}/auth-login/sign-in`,
        signUp: `${this._domain}/auth-login/sign-up`,

        users: `${this._domain}/users`,
        categories: `${this._domain}/admin/categories`,
        products: `${this._domain}/admin/category-items`,
        news: `${this._domain}/admin/news`,
        newsCategory: `${this._domain}/admin/news-categories`,
        features: `${this._domain}/admin/features`,
        vacancies: `${this._domain}/admin/vacancies`,
        candidates: `${this._domain}/admin/candidates`,
        issues: `${this._domain}/admin/issues`,

        newsClient: `${this._domain}/news`,
        newsCategoryClient: `${this._domain}/news-categories`,

    }

    public get signIn() { return this._links.signIn; }
    public get signUp() { return this._links.signUp; }

    public get users() { return this._links.users; }
    public get categories() { return this._links.categories; }
    public get products() { return this._links.products; }
    public get news() { return this._links.news; }
    public get newsCategory() { return this._links.newsCategory; }
    public get nltdevskeletons() { return this._links.products; }
    public get features() { return this._links.features; }
    public get vacancies() { return this._links.vacancies; }
    public get candidates() { return this._links.candidates; }
    public get issues() { return this._links.issues; }

    public get newsClient() { return this._links.newsClient; }
    public get newsCategoryClient() { return this._links.newsCategoryClient; }

}

export const links = new Links();