import { useState } from "react";
import { INewsCategory } from "../entity/newsCategories/INewsCategory";
import { deleteNewsCategoryUseCase } from "../useCases/deleteNewsCategoryUseCase";
import { useNavigate } from "react-router-dom";
import { updateNewsCategoryUseCase } from "../useCases/updateNewsCategoryUseCase";

export const useNewsCategoryListItem = (category: INewsCategory) => {
    const [openMenu, setOpenMenu] = useState(null);
    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const onEdit = () => {
        navigate(`/dashboard/news-categories/edit/${category.id}`);
    };

    const onActiveSwitch = async () => {
        await updateNewsCategoryUseCase({ id: category.id, available: !category.available });
    };

    const onDelete = async () => {
        await deleteNewsCategoryUseCase(category.id);
    }

    return { onDelete, open, handleOpenMenu, openMenu, handleCloseMenu, onEdit, onActiveSwitch };

}