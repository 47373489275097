import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNltdevskeletonsUseCase } from "../useCases/getNltdevskeletonsUseCase";

export const useNltdevskeletons = () => {
    const navigate = useNavigate();

    useEffect(() => {
        getNltdevskeletonsUseCase();
    }, []);

    const onGoToCreateNltdevskeleton = useCallback(() => {
        navigate('/dashboard/nltdevskeletons/create');
    }, []);

    return { onGoToCreateNltdevskeleton };
}
