export const en = {
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    phone: 'Phone',
    link: 'Link',
    position: 'Position',
    skills: 'Skills',
    status: 'Status',
    rating: 'Rating',
    comment: 'Comment',
    sortNumber: 'Sort number',
    file: 'File',
    close: 'Close',
    slug: 'Slug',
    price: 'Price',
    currencyName: 'Currency name',
    currencyShortName: 'Currency short name',
    currencies: 'Currencies',
    addCurrency: 'Add currency',
    languageName: 'Language name',
    languageShortName: 'Language short name',
    addLanguage: 'Add language',
    languages: 'Languages',
    productImages: 'Product images',
    newProduct: 'New product',
    productCategory: 'Product category',
    products: 'Products',
    createProduct: 'Create product',
    editProduct: 'Edit product',
    editCategory: 'Edit category',
    save: 'Save',
    categoryIcon: 'Category icon',
    imageFormats: '(svg, png, jpg or jpeg formats, max size 1mb)',
    pressForAddFileOrDropHere: 'Press for add file or drop here',
    active: 'Active',
    inactive: 'Inactive',
    none: 'None',
    parentCategory: 'Parent category',
    description: 'Description',
    name: 'Name',
    newCategory: 'New category',
    createCategory: 'Create category',
    categories: 'Categories',
    statistics: 'Statistics',
    totalSend: 'Total send',
    success: 'Success',
    failed: 'Failed',
    notificationName: 'Notification name',
    editNotification: 'Edit notification',
    updateNotification: 'Update notification',
    cancel: 'Cancel',
    add: 'Add',
    owner: 'Owner',
    admin: 'Admin',
    marketing: 'Marketing',
    developer: 'Developer',
    role: 'Role',
    addUserByEmail: 'Add user by email',
    users: 'Users',
    addUser: 'Add user',
    usersAndPermissions: 'Users and permissions',
    apiKey: 'API key',
    copiedToClipboard: 'Copied to clipboard',
    appName: 'NLTDEV',
    signInTo: 'Sign in to',
    getStarted: 'Get started',
    doNotHaveAnAccount: 'Don’t have an account?',
    or: 'or',
    emailAddress: 'Email address',
    password: 'Password',
    login: 'Login',
    forgotPassword: 'Forgot password?',
    yourNotificationServiceProjects: 'Your Notification Service Projects',
    addProject: 'Add project',
    projectName: 'Project name',
    create: 'Create',
    firebaseCredentials: 'Firebase credentials',
    generateFirebaseCredentials: 'Generate Firebase credentials with next steps',
    uploadFile: 'Upload file',
    yourCredentialsAdded: 'Your credentials is added',
    yourCredentialsNotAdded: 'Your credentials is not added',
    notifications: 'Notifications',
    createNotification: 'Create notification',
    newNotification: 'New notification',
    title: 'Title',
    text: 'Text',
    enterTitle: 'Enter title',
    yourMessage: 'Your message',
    jsonData: 'JSON data',
    jsonDataPlaceholder: '{}',
    audience: 'Audience',
    searchNotifications: 'Search notifications...',
    edit: 'Edit',
    delete: 'Delete',
    imageUrl: 'Image URL',
    imageUrlPlaceholder: 'https://myimage.png',
    send: 'Send',
    edited: 'Edited',
    by: 'by',
    group: 'Group',
    condition: 'Condition',
    value: 'Value',
    firstName: 'First name',
    lastName: 'Last name',
    confirmPassword: 'Confirm password',
    signUp: 'Sign up',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign in',
    signUpTo: 'Sign up to',
    audienceCondition: 'Audience condition',
    audienceValue: 'Audience value',
    category: 'Category',

    nltdevskeletonIcon: 'NLTDEV Skeleton icon',
    nltdevskeletons: 'NLTDEV Skeletons',
    nltdevskeleton: 'NLTDEV Skeleton',
    createNltdevskeleton: 'Create NLTDEV Skeleton',
    editNltdevskeleton: 'Edit NLTDEV Skeleton',

    newsCategoryIcon: 'NLTDEV news category icon',
    newsCategories: 'News category',
    createNewsCategory: 'Create news category',
    editNewsCategory: 'Edit news category',

    newsIcon: 'NLTDEV news icon',
    news: 'News',
    createNews: 'Create news',
    editNews: 'Edit news',

    featuresIcon: 'NLTDEV features icon',
    features: 'Features',
    createFeatures: 'Create feature',
    editFeature: 'Edit feature',

    resources: 'Resources',
    createResource: 'Create resource',
    editResource: 'Edit resource',
    resource: 'Resource',
    resourceType: 'Resource type',

    editFeatures: 'Edit features',
    estimate: 'Estimate',
    estimateMarket: 'Estimate market',
    developmentSide: 'Development side',
    frontEnd: 'Front-end',
    backEnd: 'Back-end',
    mobile: 'Mobile',
    markdown: 'Markdown',

    candidates: {
        candidates: 'Candidates',
        createCandidate: 'Create candidate',
        editCandidate: 'Edit candidate',
        candidate: 'Candidate',
    },
    vacancies: {
        vacancies: 'Vacancies',
        createVacancy: 'Create vacancy',
        editVacancy: 'Edit vacancy',
        vacancy: 'Vacancy',
    },
    requirements: 'Requirements',
    benefits: 'Benefits',
    salary: 'Salary',
    applicationDeadline: 'Application deadline',
    available: 'Available',
    employer: 'Employer',

    reopened: 'Reopened',
    timeoriginalestimate: 'Estimate',
    aggregatetimespent: 'Time spent',
    assignee: 'Assignee',
    project_name: 'Project name',
    summary: 'Summary',
    issue_key: 'Issue key',
    notEstimated: 'Not estimated',
    percentage: 'Percentage',
    estimation: 'Estimation',
    biggestEstimationDiscrepancy: 'Biggest estimation discrepancy',
    biggestAggregatetimespen: 'Biggest aggregatetimespen',
};