import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import { ResourceHTML } from '../resourceHTML';
import { ResourceFile } from '../resourceFile';
import { ResourceMarkdown } from '../resourceMarkdown';

interface IProps {
    item: IResources;
    onChange: (value: string, field: string) => void;
}

export const ResourceItem: FC<IProps> = observer(({ item, onChange }) => {
    const { t } = useUiContext();

    return (
        <Box pt={2}>
            {item?.type === 'HTML' ? <ResourceHTML item={item} onChange={onChange} /> : null}
            {item?.type === 'MARKDOWN' ? <ResourceMarkdown item={item} onChange={onChange} /> : null}
            {item?.type === 'VIDEO' ? <ResourceFile item={item} onChange={onChange} /> : null}
            {item?.type === 'AUDIO' ? <ResourceFile item={item} onChange={onChange} /> : null}
            {item?.type === 'IMAGE' ? <ResourceFile item={item} onChange={onChange} /> : null}
        </Box>
    );
})
