import { userModel } from "src/entities/user/UserModel";
import { userService } from "src/entities/user/UserService";
import { toastService } from "src/libraries/toast";

export const googleAuthorizationUseCase = async (accessToken: string) => {
    try {
        // const response = await userService.signInGoogle(accessToken);
        // if (response?.isError) {
        //     toastService.onError('Some error');
        // } else {
        //     userModel.token = response.data.token;
        //     userModel.user = response.data.user;
        // }
        // return response;
    } catch (error) {
        console.warn('googleAuthorizationUseCase: ', error);
        return { isError: true, message: '', data: null };
    }
};