import { toastService } from "src/libraries/toast";
import { categoriesService } from "../entity/categories/CategoryService";
import { categoriesModel } from "../entity/categories/CategoryModel";

export const getCategoryUseCase = async (id: number | string) => {
    try {
        const response = await categoriesService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            categoriesModel.category = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getCategoryUseCase: ', error);
    }
}  