import React from 'react';
import SvgColor from '../../components/svg-color';

// @ts-ignore
const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const NavConfig = (t: (key: string) => string) => {
  return [
    {
      title: t('categories'),
      path: '/dashboard/categories',
      icon: icon('list-circle'),
    },
    {
      title: t('nltdevskeletons'),
      path: '/dashboard/nltdevskeletons',
      icon: icon('list-circle'),
    },
    {
      title: t('newsCategories'),
      path: '/dashboard/news-categories',
      icon: icon('newspaper'),
    },
    {
      title: t('news'),
      path: '/dashboard/news',
      icon: icon('newspaper'),
    },
    {
      title: t('features'),
      path: '/dashboard/features',
      icon: icon('newspaper'),
    },
    {
      title: t('candidates.candidates'),
      path: '/dashboard/candidates',
      icon: icon('newspaper'),
    },
    {
      title: t('vacancies.vacancies'),
      path: '/dashboard/vacancies',
      icon: icon('newspaper'),
    },
    {
      title: 'Issues',
      path: '/dashboard/issues',
      icon: icon('newspaper'),
    },
  ];
};

export default NavConfig;
