import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { googleAuthorizationUseCase } from "../useCases/googleAuthorizationUseCase";

export const useGoogleAuthorization = () => {
    const onSuccess = async (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
        await googleAuthorizationUseCase(tokenResponse.access_token);
    }

    const login = useGoogleLogin({ onSuccess: onSuccess });


    const loginGoogle = () => {
        login();
    };

    return { loginGoogle };
};