import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from 'zod';
import { candidatesModel } from "../entity/candidates/CandidatesModel";
import { candidatesService } from "../entity/candidates/CandidatesService";

const CANDIDATE = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    link: '',
    position: '',
    files: null,
    status: '',
    skills: '',
    comment: '',
    vacancy_id: null,
}

const errorsSchema = z.object({

});

export const useCandidatesCreating = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const candidate = candidatesModel.current;
    const navigate = useNavigate();

    useEffect(() => {
        candidatesModel.current = { ...CANDIDATE } as any;
        return () => { candidatesModel.current = null; }
    }, []);


    const onChange = (value: string, field: string) => {
        if (candidatesModel.current) {
            candidatesModel.current = { ...candidatesModel.current, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onCreate = async () => {
        setLoading(true);
        const result: any = errorsSchema.safeParse({ ...candidatesModel.current });
        if (result.success && candidatesModel.current) {
            const result = await candidatesService.create(candidatesModel.current as any);
            if (!result?.isError) {
                navigate(`/dashboard/candidates/edit/${result?.data.id}`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    return { candidate, errors, onChange, onCreate };

}