import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useUiContext } from 'src/UIProvider';

interface IProps {
    onClose?: () => void;
    onSave?: () => void;
    isLoading: boolean;
}

export const ResourceControls: FC<IProps> = ({ onClose, onSave, isLoading }) => {
    const { t } = useUiContext();
    return (
        <Stack direction="row" alignItems="center" justifyContent='flex-end' mb={2} pt={2}>
            <Button variant="contained" onClick={onClose} sx={{ mr: 2 }} disabled={isLoading}>
                {t('close')}
            </Button>
            <Button variant="contained" onClick={onSave} disabled={isLoading}>
                {t('save')}
            </Button>
        </Stack>
    )
}
