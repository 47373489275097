import React, { FC } from 'react';
import { Container } from '@mui/material';
import { AuthForm } from '../components/authForm';
import { SocialAuthButton } from '../components/socialAuthButton';
import { AuthDivider } from '../components/authDivider';
import { AuthSignUp } from '../components/authSignUp';
import { StyledContent } from './styles';
import { observer } from 'mobx-react';

export const AuthorizationView: FC = observer(() => {
    return (
        <Container maxWidth="sm">
            <StyledContent>
                <AuthSignUp />
                <AuthForm />
                <AuthDivider />
                <SocialAuthButton />
            </StyledContent>
        </Container>
    );
})
