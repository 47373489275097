import { toastService } from "src/libraries/toast";
import { getCategoriesUseCase } from "./getCategoriesUseCase";
import { appStateModel } from "src/entities/appState/AppStateModel";
import { categoriesService } from "../entity/categories/CategoryService";

export const deleteCategoryUseCase = async (id: number) => {
    try {
        appStateModel.isLoading = true;
        const response = await categoriesService.delete(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            await getCategoriesUseCase({ tree: true });
            toastService.onSuccess('Success');
        }
    } catch (error) {
        console.warn('deleteCategoryUseCase: ', error);
    } finally {
        appStateModel.isLoading = false;
    }
}   