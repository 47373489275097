import React, { FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Iconify } from 'src/UIKit/Iconify';
import { Box, IconButton, Typography } from "@mui/material";
import { CategoryDroppableContainer } from "../categoryDroppableContainer";
import { useUiContext } from "src/UIProvider";
import { StyledDraggedComponent, styles } from "./styles";
import { Image } from "src/UIKit/Image";
import { IOSSwitch } from "src/UIKit/IOSSwitch";
import { ContextMenu } from "src/UIKit/ContextMenu";
import { INewsCategory } from "src/modules/newsCategories/entity/newsCategories/INewsCategory";
import { useNewsCategoryListItem } from "src/modules/newsCategories/presenters/useNewsCategoryListItem";

interface IProps {
    category: INewsCategory;
    index: number;
}

export const CategoryListItem: FC<IProps> = ({ category, index }) => {
    const { t } = useUiContext();
    const { onDelete, handleOpenMenu, openMenu, handleCloseMenu, onEdit, onActiveSwitch } = useNewsCategoryListItem(category);

    return (
        <Draggable key={`Draggable${category.id}test`} draggableId={`${category.id}`} index={index} >
            {(provided, snapshot) => (
                <Box flexDirection={'row'} ref={provided.innerRef}{...provided.draggableProps} >
                    <StyledDraggedComponent isDragging={snapshot.isDragging} index={index}>
                        <Box sx={styles.row} pl={2}>
                            <Box {...provided.dragHandleProps} mt={1} ml={2} mr={2}>
                                <Iconify icon={'lucide:grip'} width={32} height={32} />
                            </Box>
                            {category.image ? <Box sx={styles.imageContainer}>
                                <Image image={category.image} sx={styles.image} />
                            </Box> : null}
                            <Box flexGrow={1} >
                                <Typography variant='body1' >{category.name}</Typography>
                            </Box>
                            <Box width={120} >
                                <IOSSwitch label={t('active')} name="available" onChange={onActiveSwitch} checked={category.available} />
                            </Box>
                            <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                            <ContextMenu open={openMenu} onClose={handleCloseMenu} onDelete={onDelete} onEdit={onEdit} />
                        </Box>
                    </StyledDraggedComponent>
                </Box>
            )}
        </Draggable>
    );
};
