import { MobXRepository } from "../../../../repository/MobXRepository"
import { INltdevskeleton } from "./INltdevskeleton";

export interface INltdevskeletonsModel {
    nltdevskeletons: { count: number; rows: INltdevskeleton[] } | null;
    nltdevskeleton: INltdevskeleton | null;
}

class NltdevskeletonsModel implements INltdevskeletonsModel {
    private nltdevskeletonsRepository = new MobXRepository<{ count: number; rows: INltdevskeleton[] } | null>(null);
    private nltdevskeletonRepository = new MobXRepository<INltdevskeleton | null>(null);

    get nltdevskeletons() {
        return this.nltdevskeletonsRepository.data;
    }

    set nltdevskeletons(data: { count: number; rows: INltdevskeleton[] } | null) {
        this.nltdevskeletonsRepository.save(data);
    }

    get nltdevskeleton() {
        return this.nltdevskeletonRepository.data;
    }

    set nltdevskeleton(data: INltdevskeleton | null) {
        this.nltdevskeletonRepository.save(data);
    }

    clear = () => {
        this.nltdevskeletons = null;
        this.nltdevskeleton = null;
    }

}

export const nltdevskeletonsModel = new NltdevskeletonsModel();
