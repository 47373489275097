import React, { FC, memo } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { INewsCategoryClient } from 'src/modules-client/news/entity/newsCategoriesClient/INewsCategoryClient';
import { useNavigate, useParams } from 'react-router-dom';
import cx from "classnames";
import { newsClientService } from 'src/modules-client/news/entity/newsClient/NewsClientService';

interface INewsHomeHeader {
    sections: INewsCategoryClient[] | null;
    title: string;
}

export const NewsHomeHeader: FC<INewsHomeHeader> = memo(({ sections, title }) => {
    const slug = useParams().slug;
    const navigate = useNavigate();

    const onSelectCategory = (slug: string) => {
        newsClientService.list({ limit: 20, offset: 0, slug: slug });
        navigate('/news/category/' + slug);
    }

    return (
        <React.Fragment>
            <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}>
                {/* <Button size="small">Subscribe</Button> */}
                <Typography component="h2" variant="h5" color="inherit" align="center" noWrap sx={{ flex: 1 }} >{title}</Typography>
                {/* <Button variant="outlined" size="small">Sign up</Button> */}
            </Toolbar>
            <Toolbar component="nav" variant="dense" sx={{ justifyContent: 'space-between', overflowX: 'auto' }} >
                {sections?.map((section) => (
                    <div key={section.id} onClick={() => onSelectCategory(section.slug)}
                        className={cx('cursor-pointer px-3 py-1 hover:bg-gray-300 rounded-md hover:text-gray-800 transition-colors duration-300 ease-in-out',
                            { 'bg-gray-300 text-gray-800': slug === section.slug }
                        )}
                    >
                        <span className='h-8 whitespace-nowrap overflow-hidden text-ellipsis'>{section.name}</span>
                    </div>
                ))}
            </Toolbar>
        </React.Fragment>
    );
});