import React, { FC } from 'react';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Iconify } from '../../../../../UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react';
import { useRegistration } from 'src/modules/authentication/presenters/useRegistration';

export const RegistrationForm: FC = observer(() => {
    const { t } = useUiContext();
    const {
        isFirstNameError, isLastNameError, isEmailError, isPasswordError, isPasswordConfirmationError,
        firstName, lastName, email, password, passwordConfirmation, isLoading, isPasswordVisible, isPasswordConfirmationVisible,
        onFirstNameChange, onLastNameChange, onEmailChange, onPasswordChange, onPasswordConfirmationChange,
        onPasswordVisibility, onPasswordConfirmationVisibility, onSubmit
    } = useRegistration();

    return (
        <Box>
            <Stack spacing={3}>
                <TextField error={isFirstNameError} name="firstNameRegistration" label={t('firstName')} value={firstName} onChange={onFirstNameChange} />
                <TextField error={isLastNameError} name="lastNameRegistration" label={t('lastName')} value={lastName} onChange={onLastNameChange} />
                <TextField error={isEmailError} type='new-email' name="emailRegistration" label={t('emailAddress')} value={email} onChange={onEmailChange} />
                <TextField
                    error={isPasswordError}
                    name="passwordRegistration"
                    label={t('password')}
                    value={password}
                    onChange={onPasswordChange}
                    type={isPasswordVisible ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onPasswordVisibility} edge="end">
                                    <Iconify icon={isPasswordVisible ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    error={isPasswordConfirmationError}
                    name="password confirmationRegistration"
                    label={t('confirmPassword')}
                    value={passwordConfirmation}
                    onChange={onPasswordConfirmationChange}
                    type={isPasswordConfirmationVisible ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onPasswordConfirmationVisibility} edge="end">
                                    <Iconify icon={isPasswordConfirmationVisible ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <LoadingButton sx={{ mt: 2 }} loading={isLoading} fullWidth size="large" type="submit" variant="contained" onClick={onSubmit}>
                {t('signUp')}
            </LoadingButton>
        </Box>
    );
})
