import { IStorage, storage } from "src/libraries/storage";
import { MobXRepository } from "../../repository/MobXRepository"
import { IUser } from "./IUser";
import Cookies from 'js-cookie';

export interface IUserModel {
    token: string | null;
    user: IUser | null;
}

class UserModel implements IUserModel {
    private userRepository = new MobXRepository<IUser>();
    private tokenRepository = new MobXRepository<string | null>(null);

    constructor(private storage: IStorage) {
        this.load();
    }

    private load = () => {
        const user = this.storage.get('STORAGE_USER');
        user && this.userRepository.save(user);
        const token = Cookies.get('STORAGE_TOKEN');
        token && this.tokenRepository.save(token);
    }

    private persistToken = (data: string | null) => {
        if (data) {
            Cookies.set('STORAGE_TOKEN', data);
        } else {
            Cookies.remove('STORAGE_TOKEN');
        }
    };

    private persistUser = (data: IUser | null) => {
        if (data) {
            this.storage.set('STORAGE_USER', data);
        } else {
            this.storage.remove('STORAGE_USER');
        }
    }

    public get token() {
        return this.tokenRepository.data;
    }

    public set token(token: string | null) {
        this.tokenRepository.save(token);
        this.persistToken(token);
    }

    public get user() {
        return this.userRepository.data;
    }

    public set user(user: IUser | null) {
        this.userRepository.save(user);
        this.persistUser(user);
    }

    public clear() {
        this.user = null;
        this.token = null;
    }

}

export const userModel = new UserModel(storage);
