import React, { FC } from 'react';
import { Box } from '@mui/material';
import { IResources } from 'src/modules/resources/entity/resources/IResources';

interface IProps {
    item: IResources;
}

export const PreviewVideo: FC<IProps> = ({ item }) => {

    return (
        <Box sx={{ height: '220px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <video style={{ height: '200px' }} controls src={item.file?.path}></video>
        </Box>
    )
}
