import { I18n } from 'i18n-js';
import { ILocalization, Locale } from './ILocalization';
import { IRepository } from '../../repository/IRepository';
import { MobXRepository } from '../../repository/MobXRepository';
import { storage } from 'src/libraries/storage';
import { en } from './dictionaries/en';
import { de } from './dictionaries/de';
import { fr } from './dictionaries/fr';

const DEFAULT_LOCALE = 'en';

class Localization implements ILocalization {
    private i18n!: I18n

    constructor(private localizationStore: IRepository<string>) {
        this.i18n = new I18n();
        this.i18n.enableFallback = true;
        this.i18n.translations = { en, de, fr };
        this.onLoad();
    }

    private onLoad = () => {
        const savedLocale = storage.get('I18N_LOCALE') || DEFAULT_LOCALE;
        this.localizationStore.save(savedLocale);
    }

    private onPersist = (data: Locale) => {
        storage.set('I18N_LOCALE', data);
    }

    get locales() {
        return Object.keys(this.i18n.translations);
    }

    get locale() {
        return this.localizationStore.data as Locale || DEFAULT_LOCALE as Locale;
    }

    t = (key: string) => {
        const locale = this.localizationStore.data;
        return this.i18n.t(key, { locale: locale });
    }

    setLocale = (locale) => {
        this.localizationStore.save(locale);
        this.onPersist(locale);
    }

}

const localizationStore = new MobXRepository<string>();
export const localization = new Localization(localizationStore);
