import { useState } from "react";
import { IResources } from "../entity/resources/IResources";
import { useNavigate } from "react-router-dom";
import { updateResourceUseCase } from "../useCases/updateResourceUseCase";
import { deleteResourceUseCase } from "../useCases/deleteResourceUseCase";

export const useResourcesItem = (item: IResources, link?: string, entityId?: number, onUpdate?: () => Promise<void>) => {
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate();

    const onEditOpen = () => {
        setEdit(!edit);
    };

    const onEditClose = () => {
        setEdit(false);
    };

    const onDelete = async () => {
        if (link && entityId) {
            await deleteResourceUseCase(link, entityId, item.id);
            await onUpdate?.();
        }
    };

    return { edit, onEditOpen, onEditClose, onDelete }
}