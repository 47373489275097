import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCategoriesUseCase } from "../useCases/getCategoriesUseCase";
import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { updateCategoryUseCase } from "../useCases/updateCategoryUseCase";

export const useCategories = () => {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getCategoriesUseCase({ tree: true });
    }, []);

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onGoToCreateCategory = useCallback(() => {
        navigate('/dashboard/categories/create');
    }, []);

    const onDragEndCategory = useCallback(async (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const parentId = result.type.split('_')[2];
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        let reorderCategories = [...categoriesModel.categoriesTree];
        let orders: { id: number, sortNumber: number }[] = [];

        if (parentId) {
            const reorderSubCategories = (categories: any[]) => {
                return categories.map((category) => {
                    if (category.id === Number(parentId)) {
                        const [removed] = category.children.splice(sourceIndex, 1);
                        category.children.splice(destinationIndex, 0, removed);
                        orders = category.children.map((child, index) => ({ id: child.id, sortNumber: index + 1 }));
                    } else if (category.children) {
                        const children = reorderSubCategories(category.children);
                        category.children = children;
                    }
                    return category;
                });
            }
            reorderCategories = reorderSubCategories(reorderCategories);
        } else {
            const [removed] = reorderCategories.splice(sourceIndex, 1);
            reorderCategories.splice(destinationIndex, 0, removed);
            categoriesModel.categoriesTree = reorderCategories;
            orders = reorderCategories.map((category, index) => ({ id: category.id, sortNumber: index + 1 }));
        }
        categoriesModel.categoriesTree = reorderCategories;
        await updateCategoryUseCase({ id: Number(result.draggableId), sort_number: destinationIndex + 1 });
    }, []);

    return { search, onDragEndCategory, onSearch, onGoToCreateCategory };
}
