import React, { FC } from 'react';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import { FileDropzone } from 'src/UIKit/FileDropzone';
import { Image } from 'src/UIKit/Image';
import { Iconify } from 'src/UIKit/Iconify';

interface IProps {
    item: IResources;
    onChange: (value: any, field: string) => void;
}

export const ResourceFile: FC<IProps> = observer(({ item, onChange }) => {
    const { t } = useUiContext();

    const onChangeImage = (files: File[]) => {
        if (files?.[0]) {
            onChange(files?.[0], 'file')
        }
    };

    const getImageUrl = () => {
        if (item?.type === 'IMAGE' && item?.file && item?.file?.path.includes('http')) {
            return item?.file?.path;
        } else if (item?.type === 'IMAGE' && item?.file) {
            return URL.createObjectURL(item?.file as any);
        }
        return '';
    };

    const covers = {
        'VIDEO': '/assets/images/news/video.png',
        'AUDIO': '/assets/images/news/audio.png',
        'IMAGE': getImageUrl(),
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={5.5} md={4.5} lg={3.5}  >
            {item?.file ? (
                    <Box display='flex' flexDirection='column' alignItems='flex-start'>
                        <Stack direction="row" justifyContent="flex-start">
                            <img src={covers[item.type]} style={{ width: '110px', height: '110px', objectFit: 'contain' }} />
                            <IconButton sx={{ height: 50, width: 50 }} onClick={() => onChange(null, 'file')}>
                                <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                        </Stack>
                        <Typography variant='body1' align='left' style={{ wordBreak: 'break-word' }}>
                            {item?.file?.name}
                        </Typography>
                    </Box>
                ) : null}
            </Grid>
            <Grid item xs={12} sm={6.5} md={7.5} lg={8.5}  >
                <FileDropzone onDrop={onChangeImage} />
            </Grid>
        </Grid>
    );
})
