import { toastService } from "src/libraries/toast"; 
import { featuresModel } from "../entity/features/FeaturesModel";
import { featuresService } from "../entity/features/FeaturesService";
import { GetFeaturesDto } from "../entity/features/dto/list-features-dto";

export const getFeaturesUseCase = async (body?: GetFeaturesDto) => {
    try {
        const response = await featuresService.list(body);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            featuresModel.features = response.data;
        }
    } catch (error) {
        console.warn('getFeaturesUseCase: ', error);
    }
}   