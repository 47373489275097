import React, { FC } from 'react';
import { Card, Stack } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { TextInput } from 'src/UIKit/TextInput';
import { ResourceItem } from '../components/resourceItem';
import { ResourceControls } from '../components/resourceControls';
import { IResources } from '../../entity/resources/IResources';
import { useResourceEdit } from '../../presenters/useResourceEdit';

interface IProps {
    item: IResources;
    length: number;
    link: string;
    entityId: number;
    close: () => void;
    onUpdate?: () => Promise<void>;
}

export const ResourcesEditView: FC<IProps> = observer(({ item, link, entityId, onUpdate, length, close }) => {
    const { t } = useUiContext();
    const { sort_numbers, sort_number, resource, errors, onChange, TYPES, typeObject, onUpdateResource, isLoading } = useResourceEdit(link, entityId, close, item, length, onUpdate);

    return (
        <Card sx={{ p: 2, my: 2 }}>
            <Stack spacing={1}>
                <Select label={t('resourceType')} name='type' onChange={onChange} value={typeObject?.name || ''} options={TYPES} required />
                <TextInput label={t('name')} name='name' onChange={onChange} value={resource?.name} error={errors} />
                <Select label={t('sortNumber')} name='sort_number' onChange={onChange} value={sort_number?.name || ''} options={sort_numbers} required />
                <ResourceItem item={resource} onChange={onChange} />
                <ResourceControls onClose={close} onSave={onUpdateResource} isLoading={isLoading} />
            </Stack>
        </Card>
    );
})
