import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import { Iconify } from 'src/UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { ContextMenu } from 'src/UIKit/ContextMenu';
import { useCandidatesTableItem } from '../../../presenters/useCandidatesTableItem';
import { ICandidates } from '../../../entity/candidates/ICandidates';

interface IProps {
    item: ICandidates;
}

export const CandidatesTableRow: FC<IProps> = memo(({ item }) => {
    const { t } = useUiContext();
    const { open, onOpenMenu, onCloseMenu, onDelete, onEdit, onActiveSwitch } = useCandidatesTableItem(item);

    return (
        <>
            <TableRow hover >
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap> {item.first_name} {item.last_name}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.email}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.phone}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.link}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.position}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.rating}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.status}</Typography>
                </TableCell>
                <TableCell align="right">
                    <IconButton size="large" color="inherit" onClick={onOpenMenu}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <ContextMenu open={open} onClose={onCloseMenu} onDelete={onDelete} onEdit={onEdit} />
        </>
    );
})
