import { useEffect } from "react";
import { newsCategoryClientModel } from "../entity/newsCategoriesClient/NewsCategoryClientModel";
import { newsClientService } from "../entity/newsClient/NewsClientService";
import { newsClientModel } from "../entity/newsClient/NewsClientModel";

export const useNewsHome = () => {
    const lastNews = newsClientModel.list;

    useEffect(() => {
        newsClientService.list({ limit: 20, offset: 0 });
    }, []);

    return { lastNews };
}