import { toastService } from "src/libraries/toast"; 
import { vacanciesModel } from "../entity/vacancies/VacanciesModel";
import { vacanciesService } from "../entity/vacancies/VacanciesService";
import { GetVacanciesDto } from "../entity/vacancies/dto/list-vacancies-dto";

export const getVacanciesUseCase = async (body?: GetVacanciesDto) => {
    try {
        const response = await vacanciesService.list(body);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            vacanciesModel.list = response.data;
        }
    } catch (error) {
        console.warn('getVacanciesUseCase: ', error);
    }
}   