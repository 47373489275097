import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import { Iconify } from 'src/UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { ContextMenu } from 'src/UIKit/ContextMenu';
import { INltdevskeleton } from 'src/modules/nltdevskeletons/entity/nltdevskeletons/INltdevskeleton';
import { styles } from './styles';
import { useNltdevskeletonsTableItem } from '../../../presenters/useNltdevskeletonsTableItem';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';

interface IProps {
    item: INltdevskeleton;
}

export const NltdevskeletonsTableRow: FC<IProps> = memo(({ item }) => {
    const { t } = useUiContext();
    const { open, onOpenMenu, onCloseMenu, onDelete, onEdit, onActiveSwitch } = useNltdevskeletonsTableItem(item);

    return (
        <>
            <TableRow hover  >
                <TableCell align="center">
                    {item.image ? <img src={item.image.path} alt="image" style={styles.image as any} /> : 'no image'}
                </TableCell>
                <TableCell component="th" scope="row" padding="none" align="center">
                    <Typography variant="subtitle2" noWrap>
                        {item.name}
                    </Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.description}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.slug}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.category_id}</Typography>
                </TableCell>
                <TableCell align={'center'} width={120}>
                    <Box width={120} >
                        <IOSSwitch label={t('active')} name="available" onChange={onActiveSwitch} checked={item.available} />
                    </Box>
                </TableCell>
                <TableCell align="right">
                    <IconButton size="large" color="inherit" onClick={onOpenMenu}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <ContextMenu open={open} onClose={onCloseMenu} onDelete={onDelete} onEdit={onEdit} />
        </>
    );
})
