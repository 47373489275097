import { useState } from "react";
import { z } from "zod";
import { IResources } from "../entity/resources/IResources";
import { createResourceUseCase } from "../useCases/createResourceUseCase";
import { updateResourceUseCase } from "../useCases/updateResourceUseCase";

const categorySchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    value: z.string().nullable(),
    image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: "File size should be less than or equal to 1 MB" }).nullable().optional(),
});

const TYPES = [{ name: 'HTML', id: 'HTML' }, { name: 'VIDEO', id: 'VIDEO' }, { name: 'AUDIO', id: 'AUDIO' }, { name: 'IMAGE', id: 'IMAGE' }, { name: 'MARKDOWN', id: 'MARKDOWN' }];
const DEFAULT_RESOURCE: any = { name: '', value: '', file: null, type: 'HTML', id: 0 };

export const useResourceCreating = (link: string, entityId: number, close: () => void, item: IResources = DEFAULT_RESOURCE, onUpdate?: () => Promise<void>) => {
    const [resource, setResource] = useState<IResources>(item);
    const [errors, setErrors] = useState({ name: '', value: '', file: '', type: '' });
    const [isLoading, setLoading] = useState(false);

    const typeObject = TYPES.find((item) => item.id === resource.type);

    const onChange = (value: string, field: string) => {
        setResource((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: '' }));
        if (field === 'type') {
            setResource((prev) => ({ ...prev, value: '', file: null }));
        }
    }

    const onCreateResource = async () => {
        setLoading(true);
        await createResourceUseCase(link, entityId, resource as any);
        await onUpdate?.();
        close();
        setLoading(false);
    }

    return { resource, errors, onChange, TYPES, typeObject, onCreateResource, isLoading };

}