import { MobXRepository } from "../../../../repository/MobXRepository"
import { INewsCategory } from "./INewsCategory";

export interface INewsCategoryModel {
    newsCategories: INewsCategory[];
    newsCategory: INewsCategory | null;
}

class NewsCategoryModel implements INewsCategoryModel {
    private newsCategoriesRepository = new MobXRepository<INewsCategory[]>([]);
    private categoryRepository = new MobXRepository<INewsCategory | null>(null); 

    get newsCategories() {
        return this.newsCategoriesRepository.data ?? [];
    }

    set newsCategories(data: INewsCategory[]) {
        this.newsCategoriesRepository.save(data);
    }

    get newsCategory() {
        return this.categoryRepository.data;
    }

    set newsCategory(data: INewsCategory | null) {
        this.categoryRepository.save(data);
    }

    clear = () => {
        this.newsCategories = [];
        this.newsCategory = null;
    }

}

export const newsCategoryModel = new NewsCategoryModel();
