import { requester } from "src/libraries/requester";
import { links } from "src/Links";
import { ApiService } from "src/repository/ApiService";
import { issuesModel } from "./IssuesModel";
import { IIssue } from "./IIssue";
import { IIssueFilters } from "./IIssueFilters";
import { toastService } from "src/libraries/toast";

class IssuesService extends ApiService<IIssue> {
    constructor() {
        super(requester, links.issues, issuesModel);
    }

    async getFilters(): Promise<IIssueFilters[]> {
        try {
            const url = links.issues + '/filters';
            const response = await requester.get(url);
            if (response?.isError) {
                toastService.onError('Some error');
            } else {
                issuesModel.filters = response.data;
            }
            return response;
        } catch (error) {
            console.warn('IssuesService -> getFilters: ', links.issues + '/filters', error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

}

export const issuesService = new IssuesService();