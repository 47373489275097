export const de = {
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'E-Mail',
    phone: 'Telefon',
    link: 'Link',
    position: 'Position',
    skills: 'Fähigkeiten',
    status: 'Status',
    rating: 'Bewertung',
    comment: 'Kommentar',
    sortNumber: 'Sortiernummer',
    file: 'Datei',
    close: 'Schließen',
    slug: 'Slug',
    price: 'Preis',
    currencyName: 'Währungsname',
    currencyShortName: 'Währungsabkürzung',
    currencies: 'Währungen',
    addCurrency: 'Währung hinzufügen',
    languageName: 'Sprachname',
    languageShortName: 'Sprachabkürzung',
    addLanguage: 'Sprache hinzufügen',
    languages: 'Sprachen',
    productImages: 'Produktbilder',
    newProduct: 'Neues Produkt',
    productCategory: 'Produktkategorie',
    products: 'Produkte',
    createProduct: 'Produkt erstellen',
    editProduct: 'Produkt bearbeiten',
    editCategory: 'Kategorie bearbeiten',
    save: 'Speichern',
    categoryIcon: 'Kategorie-Icon',
    imageFormats: '(svg, png, jpg oder jpeg Formate, max. Größe 1mb)',
    pressForAddFileOrDropHere: 'Zum Hinzufügen Datei klicken oder hierher ziehen',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    none: 'Keine',
    parentCategory: 'Übergeordnete Kategorie',
    description: 'Beschreibung',
    name: 'Name',
    newCategory: 'Neue Kategorie',
    createCategory: 'Kategorie erstellen',
    categories: 'Kategorien',
    statistics: 'Statistiken',
    totalSend: 'Gesamtsendung',
    success: 'Erfolg',
    failed: 'Fehlgeschlagen',
    notificationName: 'Benachrichtigungsname',
    editNotification: 'Benachrichtigung bearbeiten',
    updateNotification: 'Benachrichtigung aktualisieren',
    cancel: 'Abbrechen',
    add: 'Hinzufügen',
    owner: 'Besitzer',
    admin: 'Admin',
    marketing: 'Marketing',
    developer: 'Entwickler',
    role: 'Rolle',
    addUserByEmail: 'Benutzer per E-Mail hinzufügen',
    users: 'Benutzer',
    addUser: 'Benutzer hinzufügen',
    usersAndPermissions: 'Benutzer und Berechtigungen',
    apiKey: 'API-Schlüssel',
    copiedToClipboard: 'In die Zwischenablage kopiert',
    appName: 'NLTDEV',
    signInTo: 'Anmelden bei',
    getStarted: 'Loslegen',
    doNotHaveAnAccount: 'Kein Konto?',
    or: 'oder',
    emailAddress: 'E-Mail-Adresse',
    password: 'Passwort',
    login: 'Anmelden',
    forgotPassword: 'Passwort vergessen?',
    yourNotificationServiceProjects: 'Ihre Benachrichtigungsdienstprojekte',
    addProject: 'Projekt hinzufügen',
    projectName: 'Projektname',
    create: 'Erstellen',
    firebaseCredentials: 'Firebase-Anmeldedaten',
    generateFirebaseCredentials: 'Firebase-Anmeldedaten mit den nächsten Schritten generieren',
    uploadFile: 'Datei hochladen',
    yourCredentialsAdded: 'Ihre Anmeldedaten wurden hinzugefügt',
    yourCredentialsNotAdded: 'Ihre Anmeldedaten wurden nicht hinzugefügt',
    notifications: 'Benachrichtigungen',
    createNotification: 'Benachrichtigung erstellen',
    newNotification: 'Neue Benachrichtigung',
    title: 'Titel',
    text: 'Text',
    enterTitle: 'Titel eingeben',
    yourMessage: 'Ihre Nachricht',
    jsonData: 'JSON-Daten',
    jsonDataPlaceholder: '{}',
    audience: 'Zielgruppe',
    searchNotifications: 'Benachrichtigungen suchen...',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    imageUrl: 'Bild-URL',
    imageUrlPlaceholder: 'https://meinbild.png',
    send: 'Senden',
    edited: 'Bearbeitet',
    by: 'von',
    group: 'Gruppe',
    condition: 'Bedingung',
    value: 'Wert',
    firstName: 'Vorname',
    lastName: 'Nachname',
    confirmPassword: 'Passwort bestätigen',
    signUp: 'Registrieren',
    alreadyHaveAccount: 'Bereits ein Konto?',
    signIn: 'Anmelden',
    signUpTo: 'Registrieren bei',
    audienceCondition: 'Zielgruppenbedingung',
    audienceValue: 'Zielgruppenwert',
    category: 'Kategorie',

    nltdevskeletonIcon: 'NLTDEV Skelett-Icon',
    nltdevskeletons: 'NLTDEV Skelette',
    nltdevskeleton: 'NLTDEV Skelett',
    createNltdevskeleton: 'NLTDEV Skelett erstellen',
    editNltdevskeleton: 'NLTDEV Skelett bearbeiten',

    newsCategoryIcon: 'NLTDEV Nachrichtenkategorie-Icon',
    newsCategories: 'Nachrichtenkategorie',
    createNewsCategory: 'Nachrichtenkategorie erstellen',
    editNewsCategory: 'Nachrichtenkategorie bearbeiten',

    newsIcon: 'NLTDEV Nachrichten-Icon',
    news: 'Nachrichten',
    createNews: 'Nachricht erstellen',
    editNews: 'Nachricht bearbeiten',

    featuresIcon: 'NLTDEV Funktionen-Icon',
    features: 'Funktionen',
    createFeatures: 'Funktion erstellen',
    editFeature: 'Funktion bearbeiten',

    resources: 'Ressourcen',
    createResource: 'Ressource erstellen',
    editResource: 'Ressource bearbeiten',
    resource: 'Ressource',
    resourceType: 'Ressourcentyp',

    editFeatures: 'Funktionen bearbeiten',
    estimate: 'Schätzen',
    estimateMarket: 'Markt schätzen',
    developmentSide: 'Entwicklungsseite',
    frontEnd: 'Front-End',
    backEnd: 'Back-End',
    mobile: 'Mobil',
    markdown: 'Markdown',

    candidates: {
        candidates: 'Kandidaten',
        createCandidate: 'Kandidat erstellen',
        editCandidate: 'Kandidat bearbeiten',
        candidate: 'Kandidat',
    },
    vacancies: {
        vacancies: 'Stellenangebote',
        createVacancy: 'Stellenangebot erstellen',
        editVacancy: 'Stellenangebot bearbeiten',
        vacancy: 'Stellenangebot',
    },
    requirements: 'Anforderungen',
    benefits: 'Vorteile',
    salary: 'Gehalt',
    applicationDeadline: 'Bewerbungsfrist',
    available: 'Verfügbar',
    employer: 'Arbeitgeber',

    reopened: 'Reopened',
    timeoriginalestimate: 'Estimate',
    aggregatetimespent: 'Time spent',
    assignee: 'Assignee',
    project_name: 'Project name',
    summary: 'Summary',
    issue_key: 'Issue key',
    notEstimated: 'Not estimated',
    percentage: 'Percentage',
};