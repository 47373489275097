import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { FileDropzone } from 'src/UIKit/FileDropzone';
import { Iconify } from 'src/UIKit/Iconify';
import { Image } from 'src/UIKit/Image';
import { observer } from 'mobx-react';
import { featuresModel } from '../../../entity/features/FeaturesModel';
 
export const FeaturesImageDropZone: FC = observer(() => {
    const { t, colors } = useUiContext();
    const features = featuresModel.feature;

    const onChangeImage = (files: File[]) => {
        if (features && files?.[0]) {
            featuresModel.feature = { ...features, image: files?.[0] as any };
        }
    }

    const omRemoveImage = () => {
        if (features) {
            featuresModel.feature = { ...features, image: null as any };
        }
    }

    return (
        <Box>
            {
                features?.image
                    ? <Box sx={{ position: 'relative', display: 'flex', flex: 1, justifyContent: 'center' }}>
                        <Image image={features?.image} sx={{ alignSelf: 'center', display: 'flex', flex: 1, borderRadius: 1.5, objectFit: 'contain' }} />
                        <IconButton
                            sx={{
                                height: 40, width: 40, backgroundColor: colors.card, position: 'absolute', right: '10px', top: '10px'
                            }}
                            onClick={omRemoveImage} >
                            <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                    </Box >
                    : <FileDropzone onDrop={onChangeImage} />
            }
        </Box>
    );
})
