import { toastService } from "src/libraries/toast";
import { getCategoriesUseCase } from "./getCategoriesUseCase";
import { categoriesService } from "../entity/categories/CategoryService";
import { UpdateCategoryDto } from "../entity/categories/dto/update-category-dto";

export const updateCategoryUseCase = async (body: UpdateCategoryDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body?.image && !body.image?.path?.includes('http')) {
                formData.append('image', body.image, body.image.name)
            } else if (key === 'image' && !body.image?.path?.includes('http')) {
                formData.append('image', 'null')
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await categoriesService.update(body.id, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            toastService.onSuccess('Success');
            await getCategoriesUseCase({ tree: true });
        }
        return response;
    } catch (error) {
        console.warn('updateCategoryUseCase: ', error);
    }
}  