export const COLORS = {
    dark: {
        notification: '#000000',
        title: '#FFFFFF', // Main3
        text: '#F6F7F8', // done - Main2 - #F6F7F8
        inactiveText: '#B6C5E1', // done
        invertText: '#7D8FB2', // Background_Main
        buttonText: '#FFFFFF', // done - Background_Main
        icon: '#FFFFFF', // - done - Main3
        invertIcon: '#000000', // 
        inactiveIcon: '#7D8FB3', // done - Secondary4
        primary: '#42FE73', // Main1
        primarySecondary: '#0D8BDD', // Main1
        background: '#f1f4fb', // Background_Main
        card: '#FFFFFF', // dark
        header: '#0F1D37', // dark
        error: '#FF7979', // - Secondary.7
        success: '#1AC795',
        border: '#C9D6F0',
    },
};