import { MenuItem, Popover } from '@mui/material';
import React, { FC, memo } from 'react';
import { Iconify } from 'src/UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';

interface IProps {
    open: any;
    onClose: () => void;
    onDelete: () => void;
    onEdit: () => void;
}

export const ContextMenu: FC<IProps> = memo(({ onDelete, onEdit, open, onClose }) => {
    const { t } = useUiContext();

    const handleCloseMenu = () => {
        onClose();
    };

    const onHandleDelete = () => {
        onDelete();
    };

    const onHandleEdit = () => {
        onEdit();
    };

    return (
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{ sx: { p: 1, width: 140, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75, }, }, }}
        >
            <MenuItem onClick={onHandleEdit}>
                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />{t('edit')}
            </MenuItem>
            <MenuItem sx={{ color: 'error.main' }} onClick={onHandleDelete}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />{t('delete')}
            </MenuItem>
        </Popover>
    );
})
