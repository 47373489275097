import { toastService } from "src/libraries/toast";
import { getNltdevskeletonsUseCase } from "./getNltdevskeletonsUseCase";
import { CreateNltdevskeletonDto } from "../entity/nltdevskeletons/dto/create-category-dto";
import { nltdevskeletonsService } from "../entity/nltdevskeletons/NltdevskeletonsService";

export const createNltdevskeletonUseCase = async (body: CreateNltdevskeletonDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body?.image) {
                formData.append('image', body.image, body.image.name)
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await nltdevskeletonsService.create(formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            await getNltdevskeletonsUseCase();
        }
        return response;
    } catch (error) {
        console.warn('createNltdevskeletonUseCase: ', error);
    }
}  