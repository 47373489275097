import { FC } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { Avatar, Box, Stack, Typography, } from "@mui/material";
import React from "react";
import { useUiContext } from "src/UIProvider";
import { Iconify } from "src/UIKit/Iconify";

interface IProps extends DropzoneOptions {
    onDrop: (file: File[]) => void;
}

export const FileDropzone: FC<IProps> = ({ ...other }) => {
    const { t } = useUiContext();
    const { getRootProps, getInputProps, isDragActive } = useDropzone(other);

    return (
        <Box
            sx={{
                alignItems: "center",
                border: 1,
                flex: 1,
                borderRadius: 1,
                borderStyle: "dashed",
                borderColor: "divider",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                outline: "none",
                p: 4,
                ...(isDragActive && { backgroundColor: "action.active", opacity: 0.5, }),
                "&:hover": { backgroundColor: "action.hover", cursor: "pointer", opacity: 0.5, },
            }}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <Stack alignItems="center" spacing={2}>
                <Stack spacing={1}>
                    <Avatar sx={{ height: 64, width: 64, }}  >
                        <Iconify color='#000' icon={'bi:upload'} width={32} height={32} />
                    </Avatar>
                </Stack>
                <Stack spacing={1}>
                    <Typography variant="h6" >{t('pressForAddFileOrDropHere')}</Typography>
                    <Typography color="text.secondary" variant='caption' >{t('imageFormats')}</Typography>
                </Stack>
            </Stack>
        </Box>
    );
}; 
