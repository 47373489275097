import { MobXRepository } from "../../../../repository/MobXRepository"
import { ICategory } from "./ICategory";

export interface ICategoriesModel {
    categories: ICategory[];
    categoriesTree: ICategory[];
    category: ICategory | null;
}

class CategoriesModel implements ICategoriesModel {
    private categoriesRepository = new MobXRepository<ICategory[]>([]);
    private categoriesTreeRepository = new MobXRepository<ICategory[]>([]);
    private categoryRepository = new MobXRepository<ICategory | null>(null);

    get categoriesTree() {
        return this.categoriesTreeRepository.data ?? [];
    }

    set categoriesTree(data: ICategory[]) {
        this.categoriesTreeRepository.save(data);
    }

    get categories() {
        return this.categoriesRepository.data ?? [];
    }

    set categories(data: ICategory[]) {
        this.categoriesRepository.save(data);
    }

    get category() {
        return this.categoryRepository.data;
    }

    set category(data: ICategory | null) {
        this.categoryRepository.save(data);
    }

    clear = () => {
        this.categories = [];
        this.category = null;
    }

}

export const categoriesModel = new CategoriesModel();
