import React, { FC } from 'react';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Iconify } from '../../../../../UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { useAuthorization } from 'src/modules/authentication/presenters/useAuthorization';
import { observer } from 'mobx-react';

export const AuthForm: FC = observer(() => {
    const { t } = useUiContext();
    const { isLoading, showPassword, login, password, onLogin, onShowPassword, onChangLogin, onChangPassword, errors } = useAuthorization();

    return (
        <Box>
            <Stack spacing={3}>
                <TextField
                    name="phone"
                    label={t('phone')}
                    value={login}
                    onChange={onChangLogin}
                    helperText={<Typography color="error" variant="caption">
                        {!!errors.login && errors.login}
                    </Typography>
                    }
                />
                <TextField
                    name="password"
                    label={t('password')}
                    value={password}
                    onChange={onChangPassword}
                    type={showPassword ? 'text' : 'password'}
                    helperText={<Typography color="error" variant="caption">
                        {!!errors.password && errors.password}
                    </Typography>
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onShowPassword} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {/* <FormControlLabel control={<Checkbox name="remember" />} label="Remember me" /> */}
                <Link sx={{ cursor: 'pointer' }} variant="subtitle2" underline="hover">{t('forgotPassword')}</Link>
            </Stack>
            <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" onClick={onLogin}>
                {t('login')}
            </LoadingButton>
        </Box>
    );
})
