import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useCandidates } from '../../presenters/useCandidates';
import { CandidatesTable } from '../components/table';

export const CandidatesView: FC = observer(() => {
    const { t } = useUiContext();
    const { onGoToCreate } = useCandidates();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('candidates.candidates')} buttonTitle={t('candidates.createCandidate')} onPress={onGoToCreate} />
            <CandidatesTable />
        </Container>
    );
})
