export const getStyles = () => {
    const styles = {
        editor: { 
            border: '1px solid #C4C4C4', 
            borderRadius: '5px', 
            padding: '10px',
            overflowY: 'hidden', 
            
        },
        toolbar: { 
            backgroundColor: '#F8B043', 
            borderRadius: '5px'
        },
        container: {
            "img": {
                maxWidth: "100%",
            },
            ".rdw-editor-main": {
                background: "background.default",
                px: 4,
                minHeight: "250px",
                border: "1px solid lightgray",
                zIndex: 0,
                position: "relative"
            },
            ".rdw-editor-toolbar": {
                position: "sticky",
                top: "75px",
                visibility: "inherit !important",
                zIndex: 98
            },
            "figure": {
                m: 0
            },
            ".public-DraftStyleDefault-block": {
                margin: '0px'
            },
            ".rdw-option-active": {
                backgroundColor: "rgb(223, 221, 221)"
            }
        }
    };
    return styles;
};