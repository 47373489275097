import React, { FC } from "react";
import { Box, Link, Typography } from "@mui/material";
import { useUiContext } from "src/UIProvider";
import { IIssue } from "src/modules/issues/entity/issues/IIssue";
import { styles } from "./styles";

interface IProps {
    item: IIssue;
}

export const IssueListItem: FC<IProps> = ({ item }) => {
    const { t } = useUiContext();

    return (
        <Box sx={styles.row} >
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <Link href={item.jira_issue_link} variant='body1' >{item.jira_issue_key}</Link>
            </div>
            <div className="flex flex-1 min-w-96 break-words flex-wrap h-14 overflow-hidden items-center" >
                <span className="break-words">{item.summary}</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center items-center" >
                <span>{item.project_name}</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center items-center" >
                <span>{item.assignee}</span>
            </div>
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{item.timeoriginalestimate}</span>
            </div>
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{item.aggregatetimespent}</span>
            </div>
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{item.reopend}</span>
            </div>
        </Box>
    );
};
