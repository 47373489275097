import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useNews } from '../../presenters/useNews';
import { NewsTable } from '../components/table';
import { NewsList } from '../components/newsList';

export const NewsView: FC = observer(() => {
    const { t } = useUiContext();
    const { onGoToCreateNews } = useNews();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('news')} buttonTitle={t('createNews')} onPress={onGoToCreateNews} />
            <NewsList />
            {/* <NewsTable /> */}
        </Container>
    );
})
