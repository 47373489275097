import React, { FC } from "react";
import { useUiContext } from "src/UIProvider";

export const IssuesHeader: FC = () => {
    const { t } = useUiContext();

    return (
        <div className="flex flex-row bg-slate-300 h-10" >
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{t('issue_key')}</span>
            </div>
            <div className="flex flex-1 min-w-96 justify-center items-center" >
                <span>{t('summary')}</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center items-center" >
                <span>{t('project_name')}</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center items-center" >
                <span>{t('assignee')}</span>
            </div>
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{t('timeoriginalestimate')}</span>
            </div>
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{t('aggregatetimespent')}</span>
            </div>
            <div className="flex w-24 min-w-24 justify-center items-center" >
                <span>{t('reopened')}</span>
            </div>
        </div>
    );
};
