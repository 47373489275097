import { useState } from "react";
import { ICategory } from "../entity/categories/ICategory";
import { deleteCategoryUseCase } from "../useCases/deleteCategoryUseCase";
import { useNavigate } from "react-router-dom";
import { updateCategoryUseCase } from "../useCases/updateCategoryUseCase";

export const useCategoryListItem = (category: ICategory) => {
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const onOpenCloseAccordion = () => {
        setOpen(!open);
    };

    const onEdit = () => {
        navigate(`/dashboard/categories/edit/${category.id}`);
    };

    const onActiveSwitch = async () => {
        await updateCategoryUseCase({ id: category.id, available: !category.available });
    };

    const onDelete = async () => {
        await deleteCategoryUseCase(category.id);
    }

    return { onDelete, open, onOpenCloseAccordion, handleOpenMenu, openMenu, handleCloseMenu, onEdit, onActiveSwitch };

}