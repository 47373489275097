import React, { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

interface IProps {
    data: {
        title: string;
        path: string;
        icon: JSX.Element;
        info?: string;
    }[]
}

export const NavbarSection: FC<IProps> = ({ data = [] }) => {
    return (
        <Box >
            <List disablePadding sx={{ p: 1 }}>
                {data.map((item) => (<NavbarItem key={item.title} item={item} />))}
            </List>
        </Box>
    );
}

const NavbarItem: FC<{ item: { title: string; path: string; icon: JSX.Element; info?: string; } }> = ({ item }) => {
    const { title, path, icon, info } = item;

    return (
        <StyledNavItem
            component={RouterLink}
            to={path}
            sx={{
                '&.active': {
                    color: 'text.primary',
                    bgcolor: 'action.selected',
                    fontWeight: 'fontWeightBold',
                },
            }}
        >
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
            <ListItemText disableTypography primary={title} />
            {info && info}
        </StyledNavItem>
    );
}
