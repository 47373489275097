import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { candidatesService } from "../entity/candidates/CandidatesService";

export const useCandidates = () => {
    const navigate = useNavigate();

    const onGoToCreate = useCallback(() => {
        navigate('/dashboard/candidates/create');
    }, []);

    return { onGoToCreate };
}
