import { toastService } from "src/libraries/toast";
import { nltdevskeletonsService } from "../entity/nltdevskeletons/NltdevskeletonsService";
import { nltdevskeletonsModel } from "../entity/nltdevskeletons/NltdevskeletonsModel";

export const getNltdevskeletonUseCase = async (id: number | string) => {
    try {
        const response = await nltdevskeletonsService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            nltdevskeletonsModel.nltdevskeleton = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getNltdevskeletonUseCase: ', error);
    }
}  