import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createNltdevskeletonUseCase } from "../useCases/createNltdevskeletonUseCase";
import { z } from 'zod';
import { nltdevskeletonsModel } from "../entity/nltdevskeletons/NltdevskeletonsModel";
import { getCategoriesUseCase } from "src/modules/categories/useCases/getCategoriesUseCase";
import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { ICategory } from "src/modules/categories/entity/categories/ICategory";
import { getNltdevskeletonUseCase } from "../useCases/getNltdevskeletonUseCase";
import { updateNltdevskeletonUseCase } from "../useCases/updateNltdevskeletonUseCase";

const nltdevskeletonSchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    description: z.string().nullable(),
    slug: z.string().min(1, { message: "Not empty" }),
    category_id: z.union([z.number(), z.string()]),
    available: z.boolean(),
    image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: "File size should be less than or equal to 1 MB" }).nullable().optional(),
});

export const useNltdevskeletonEditing = () => {
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', category_id: '', image: '' });
    const nltdevskeleton = nltdevskeletonsModel.nltdevskeleton;
    const categories = categoriesModel.categories;
    const navigate = useNavigate();

    useEffect(() => {
        if (!categories.length) {
            getCategoriesUseCase({ tree: true });
        }
        getNltdevskeletonUseCase(Number(id));
        return () => { nltdevskeletonsModel.nltdevskeleton = null; }
    }, []);

    const parent = categories?.find((item: ICategory) => item.id === nltdevskeleton?.category_id);

    const options = categories?.map((item: ICategory) => ({ id: item.id, name: item.name, value: item.id }));

    const onChange = (value: string, field: string) => {
        if (nltdevskeletonsModel.nltdevskeleton) {
            nltdevskeletonsModel.nltdevskeleton = { ...nltdevskeletonsModel.nltdevskeleton, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onUpdateNltdevskeleton = async () => {
        setLoading(true);
        const result: any = nltdevskeletonSchema.safeParse({ ...nltdevskeletonsModel.nltdevskeleton });
        if (result.success && nltdevskeletonsModel.nltdevskeleton) {
            const result = await updateNltdevskeletonUseCase(nltdevskeletonsModel.nltdevskeleton as any);
            if (!result?.isError) {
                navigate(`/dashboard/nltdevskeletons`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    return { nltdevskeleton, parent, errors, options, onChange, onUpdateNltdevskeleton };

}