import { useState } from "react";
import { INltdevskeleton } from "../entity/nltdevskeletons/INltdevskeleton";
import { useNavigate } from "react-router-dom";
import { deleteNltdevskeletonUseCase } from "../useCases/deleteNltdevskeletonUseCase";
import { updateNltdevskeletonUseCase } from "../useCases/updateNltdevskeletonUseCase";

export const useNltdevskeletonsTableItem = (item: INltdevskeleton) => {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const onOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const onCloseMenu = () => {
        setOpen(null);
    };

    // const onHandleClick = () => {
    //     onPress(item.id);
    // };

    const onDelete = async () => {
        await deleteNltdevskeletonUseCase(item.id);
    };

    const onEdit = () => {
        navigate('/dashboard/nltdevskeletons/edit/' + item.id);
    };

    const onActiveSwitch = async () => {
        await updateNltdevskeletonUseCase({ id: item.id, available: !item.available });
    }

    return { open, onActiveSwitch, onOpenMenu, onCloseMenu, onDelete, onEdit }
}