import React, { FC } from 'react';
import { Card, Container, } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { useIssues } from '../../presenters/useIssues';
import { IssueListItem } from '../components/issueListItem';
import { IssuesHeader } from '../components/issuesHeader';
import { IssuesFilters } from '../components/issuesFilters';
import { IssuesStatistics } from '../components/issuesStatistics';

export const IssuesView: FC = observer(() => {
    const { t } = useUiContext();
    const { issues, filters, setFilters } = useIssues();

    // console.log('filters ----> ', filters);

    return (
        <Container maxWidth="xl">
            <Card sx={{ overflow: 'scroll' }}>
                <IssuesStatistics />
                <IssuesFilters filters={filters} setFilters={setFilters} />
                <IssuesHeader />
                <Scrollbar sx={{ height: "calc(100vh - 340px)", position: "relative" }} >
                    {issues?.map((item, index) => (<IssueListItem item={item} key={item.id} />))}
                </Scrollbar>
            </Card>
        </Container>
    );
})
