import React, { FC } from "react"
import { Button, Stack } from "@mui/material"
import { Iconify } from "src/UIKit/Iconify"
import { useGoogleAuthorization } from "src/modules/authentication/presenters/useGoogleAuthorization";
import { observer } from "mobx-react-lite";

export const SocialAuthButton: FC = observer(() => {
    const { loginGoogle } = useGoogleAuthorization();

    return (
        <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" color="inherit" variant="outlined" onClick={loginGoogle}>
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
            </Button>
            <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />
            </Button>
            <Button fullWidth size="large" color="inherit" variant="outlined">
                <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />
            </Button>
        </Stack>
    )
})