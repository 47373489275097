import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from 'zod';
import { candidatesModel } from "../entity/candidates/CandidatesModel";
import { candidatesService } from "../entity/candidates/CandidatesService";

const errorsSchema = z.object({
});

export const useCandidatesEditing = () => {
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', image: '', category_id: '' });
    const candidate = candidatesModel.current;
    const navigate = useNavigate();

    useEffect(() => {
        candidatesService.get(Number(id));
        return () => { candidatesModel.current = null; }
    }, []);

    const onChange = (value: any, field: string) => {
        if (candidatesModel.current) {
            candidatesModel.current = { ...candidatesModel.current, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onUpdate = async () => {
        setLoading(true);
        const result: any = errorsSchema.safeParse({ ...candidatesModel.current });
        if (result.success && candidatesModel.current) {
            await candidatesService.update(candidatesModel.current as any);
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    const onRefresh = async () => {
        await candidatesService.get(Number(id));
    }

    return { candidate, errors, onChange, onUpdate, onRefresh };

}