import React, { FC } from 'react';
import { Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { TableHeader } from 'src/UIKit/TableHeader';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { CandidatesTableRow } from '../tableRow';
import { candidatesModel } from '../../../entity/candidates/CandidatesModel';
import { useCandidatesTable } from '../../../presenters/useCandidatesTable';

const TABLE_HEAD = ['Name', 'Email', 'Phone', 'Links', 'Position', 'Rating', 'Status', ''];

export const CandidatesTable: FC = observer(() => {
    const { t } = useUiContext();
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useCandidatesTable();

    return (
        <Card>˝
            <ListToolbar search={search} onSearch={onSearch} placeholder={t('candidates.candidates')} />
            <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                    <TableHeader headLabels={TABLE_HEAD} />
                    <TableBody>
                        {candidatesModel.list?.rows?.map((item) => (<CandidatesTableRow item={item} key={item.id} />))}
                    </TableBody>
                    {/* <TableEmptyList /> */}
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={candidatesModel.list?.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </Card>
    );
})
