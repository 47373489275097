import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';

interface IProps {
    item: IResources;
}

export const PreviewHTML: FC<IProps> = observer(({ item }) => {
    const html = item.value?.replace(/<p>/g, '').replace(/<\/p>/g, '<br>');

    return (
        <div dangerouslySetInnerHTML={{ __html: html }} />
    );
})
