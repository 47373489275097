import { observer } from 'mobx-react-lite';
import React, { FC, useMemo, useState } from "react";
import { issuesModel } from "src/modules/issues/entity/issues/IssuesModel";
import { Select } from "src/UIKit/Select";
import { useUiContext } from "src/UIProvider";
import { PeriodCalender } from '../periodCalender';

interface IProps {
    filters: { jira_project_key: string; assignee: string; summary: string; time_from: string; time_to: string; }
    setFilters: (filters: { jira_project_key: string; assignee: string; summary: string; time_from: string; time_to: string; }) => void;
}

export const IssuesFilters: FC<IProps> = observer(({ filters, setFilters }) => {
    const { t } = useUiContext();
    const [showCalender, setShowCalender] = useState(false);
    const filtersKeys = issuesModel.filters;

    const { projects, assignees, projectSelected } = useMemo(() => {
        const projects = filtersKeys.projects.map((project) => ({ id: project.jira_project_key, name: project.project_name, value: project.jira_project_key }));
        const assignees = filtersKeys.assignees.map((assignee) => ({ id: assignee.assignee, name: assignee.assignee, value: assignee.assignee }));
        const projectSelected = projects?.find((item) => item.value === filters?.jira_project_key);
        return { projects, assignees, projectSelected }
    }, [filtersKeys, filters]);

    const onSelectProject = (value: string) => {
        setFilters({ ...filters, jira_project_key: value });
    }

    const onSelectAssignee = (value: string) => {
        setFilters({ ...filters, assignee: value });
    }

    const onSelectPeriod = (time_from: string, time_to: string) => {
        setFilters({ ...filters, time_from, time_to });
    }

    const onShowCalender = () => {
        setShowCalender(!showCalender);
    }

    return (
        <>

            <div className="flex flex-row " >
                <div className="flex w-96 min-w-96 justify-center items-center m-4" >
                    <Select label={''} name='project_name' onChange={onSelectProject} value={projectSelected?.name || ''} options={projects} />
                </div>
                <div className="flex w-96 min-w-96 justify-center items-center m-4" >
                    <Select label={''} name='assignee' onChange={onSelectAssignee} value={filters?.assignee || ''} options={assignees} />
                </div>
                <div className="flex w-96 min-w-96 justify-center items-center m-4 rounded-md border mt-8 h-14  relative"  >
                    <span className="flex w-96 min-w-96 justify-center items-center rounded-md border  h-14 cursor-pointer relative" onClick={onShowCalender}>
                        Period: {filters?.time_from} - {filters?.time_to}
                    </span>
                    {showCalender ? <PeriodCalender onSelectPeriod={onSelectPeriod} /> : null}
                </div>
            </div>
        </>
    );
});
