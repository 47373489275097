import { Box } from '@mui/material';
import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { NavbarSection } from 'src/modules/dashboardSideBar/ui/components/navbarSection';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { DashboardProjectHeader } from '../dashboardProjectHeader';
import { useUiContext } from 'src/UIProvider';
import NavConfig from 'src/modules/dashboardSideBar/config';

const NAV_WIDTH = 280;

export const DashboardSideBarContent: FC = observer(({ }) => {
    const { t } = useUiContext();
    
    return (
        <Scrollbar sx={{ height: 1, '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }, }}   >
            <DashboardProjectHeader />
            <NavbarSection data={NavConfig(t)} />
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );
});
