import React, { FC, useState } from "react";
import { DateRangePicker } from 'react-date-range';

interface IProps {
    onSelectPeriod: (time_from: string, time_to: string) => void;
}

export const PeriodCalender: FC<IProps> = ({ onSelectPeriod }) => {
    const [ranges, selectionRanges] = useState({ startDate: new Date(), endDate: new Date(), key: 'period' });

    const onSelectProject = ({ period }: { period: { startDate: Date; endDate: Date; key: 'period'; } }) => {
        selectionRanges(period);
        onSelectPeriod(period.startDate.toISOString()?.split('T')[0], period.endDate.toISOString()?.split('T')[0]);
    };

    return (
        <div className="absolute top-14 p-2 bg-slate-400 z-10" >
            <DateRangePicker
                ranges={[ranges]}
                onChange={onSelectProject}
            />
        </div>
    );
};
