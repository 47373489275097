import React, { FC } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Iconify } from 'src/UIKit/Iconify';

interface IProps {
    title?: string;
    onEdit?: () => void;
    onDelete?: () => void;
}

export const ResourcePreviewHeader: FC<IProps> = ({ title, onEdit, onDelete }) => {

    return (
        <Stack direction="row" alignItems="center" justifyContent={'flex-end'} height={'20px'} >
            {/* <Box sx={{ flex: 1, display: 'flex', height: 2, backgroundColor: '#E8E8E8', mr: 2, }} />
            <Typography variant='caption'>{title}</Typography>
            <Box sx={{ flex: 1, display: 'flex', height: 2, backgroundColor: '#E8E8E8', msScrollLimitXMin: 2, }} /> */}
            <Box flexDirection={'row'}>
                <IconButton sx={{ color: 'error.main', mr: 2 }} onClick={onDelete}>
                    <Iconify icon={'eva:trash-2-outline'} />
                </IconButton>
                <IconButton onClick={onEdit}>
                    <Iconify icon={'eva:edit-fill'} />
                </IconButton>
            </Box>
        </Stack>
    )
}
