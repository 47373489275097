import React, { FC } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import { PreviewAudio } from 'src/modules/resources/ui/components/previewAudio';
import { PreviewImage } from 'src/modules/resources/ui/components/previewImage';
import { PreviewMarkdown } from 'src/modules/resources/ui/components/previewMarkdown';
import { PreviewVideo } from 'src/modules/resources/ui/components/previewVideo';
import { PreviewHTML } from 'src/modules/resources/ui/components/previewHTML';

interface IProps {
    item: IResources;
}

export const ResourcesClient: FC<IProps> = observer(({ item }) => {
    return (
        <Box sx={{ maxWidth: '1000px', width: '100%' }} >
            {item?.type === 'HTML' ? <PreviewHTML item={item} /> : null}
            {item?.type === 'IMAGE' ? <PreviewImage item={item} /> : null}
            {item?.type === 'AUDIO' ? <PreviewAudio item={item} /> : null}
            {item?.type === 'VIDEO' ? <PreviewVideo item={item} /> : null}
            {item?.type === 'MARKDOWN' ? <PreviewMarkdown item={item} /> : null}
        </Box>
    );
})
