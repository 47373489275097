import { toastService } from "src/libraries/toast";
import { getNewsUseCase } from "./getNewsUseCase";
import { appStateModel } from "src/entities/appState/AppStateModel";
import { newsService } from "../entity/news/NewsService";

export const deleteNewsUseCase = async (id: number) => {
    try {
        appStateModel.isLoading = true;
        const response = await newsService.delete(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            await getNewsUseCase();
            toastService.onSuccess('Success');
        }
    } catch (error) {
        console.warn('deleteNewsUseCase: ', error);
    } finally {
        appStateModel.isLoading = false;
    }
}   