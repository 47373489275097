import { toastService } from "src/libraries/toast";
import { getNewsUseCase } from "./getNewsUseCase";
import { UpdateNewsDto } from "../entity/news/dto/update-news-dto";
import { newsService } from "../entity/news/NewsService";
import { newsModel } from "../entity/news/NewsModel";

export const updateNewsUseCase = async (body: UpdateNewsDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body?.image && !body.image?.path?.includes('http')) {
                formData.append('image', body.image, body.image.name)
            } else if (key === 'image' && !body.image?.path?.includes('http')) {
                formData.append('image', 'null')
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await newsService.update(body.id, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            toastService.onSuccess('Success');
            newsModel.newsOne = response.data;
            await getNewsUseCase();
        }
        return response;
    } catch (error) {
        console.warn('updateNewsUseCase: ', error);
    }
}  