import { userModel } from "src/entities/user/UserModel";
import { userService } from "src/entities/user/UserService";
import { UserSignUpDto } from 'src/entities/user/dto/user-sign-up.dto';
import { toastService } from "src/libraries/toast";

export const registrationUseCase = async (body: UserSignUpDto) => {
    try {
        const response = await userService.signUp(body);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            userModel.token = response.data.accessToken;
            userModel.user = response.data.user;
        }
        return response;
    } catch (error) {
        console.warn('registrationUseCase: ', error);
        return { isError: true, message: '', data: null };
    }
};