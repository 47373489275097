import React, { FC, useEffect, useRef, useState } from 'react';
import { createEditor } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { jsx } from 'slate-hyperscript';

const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]

const deserialize = (el, markAttributes: any = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent)
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null
    }

    const nodeAttributes = { ...markAttributes }

    // define attributes for text nodes
    switch (el.nodeName) {
        case 'b':
            nodeAttributes.bold = true
            break;
        case 'i':
            nodeAttributes.italic = true
            break;
            // if (nodeName === 'b') {
            //     styles.bold = true;
            // }
            // if (nodeName === 'i') {
            //     styles.italic = true;
            // }
            // if (nodeName === 'u') {
            //     styles.underline = true;
            // }
    }

    const children = Array.from(el.childNodes)
        .map(node => deserialize(node, nodeAttributes))
        .flat()

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''))
    }

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children)
        case 'BR':
            return '\n'
        case 'BLOCKQUOTE':
            return jsx('element', { type: 'quote' }, children)
        case 'P':
            return jsx('element', { type: 'paragraph' }, children)
        case 'A':
            return jsx(
                'element',
                { type: 'link', url: el.getAttribute('href') },
                children
            )
        default:
            return children
    }
}

export const SlateView: FC = () => {
    const [editor] = useState(() => withReact(createEditor()));
    const [value, setValue] = useState<any>(initialValue);

    useEffect(() => {
        const styledHtmlText = '<p>Initial <b><i>Conte</i>ntasdasd</b></p>';
        const document = new DOMParser().parseFromString(styledHtmlText, 'text/html')
        console.log(document);
        const result = deserialize(document.body);
        console.log('deserialize', result);
    }, []);

    useEffect(() => {
        window.postMessage({ type: 'TEXT_CHANGE', payload: value }, '*');
    }, [value]);

    return (
        // container
        <div className='h-full w-full bg-purple-100'>
            <Slate editor={editor} initialValue={initialValue} onChange={setValue} >
                <Editable onChange={console.log} />
            </Slate>
        </div>
    );
}
