import { Divider, Typography } from "@mui/material"
import React, { FC } from "react"
import { useUiContext } from "src/UIProvider";

export const AuthDivider: FC = () => {
    const { t } = useUiContext();

    return (
        <Divider sx={{ my: 3 }}>
            <Typography variant="body2" >{t('or').toUpperCase()}</Typography>
        </Divider>
    )
}