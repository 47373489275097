import { Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

const IOSSwitchStyled = styled((props: SwitchProps) => (<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

interface IProps {
    label?: string;
    labelSwitch?: string[];
    onChange: (value: boolean, field: string) => void;
    sx?: React.CSSProperties;
    defaultChecked?: boolean;
    name: string;
    checked?: boolean;
}

export const IOSSwitch: React.FC<IProps> = ({ name, onChange, label, sx, defaultChecked = undefined, labelSwitch = '', checked }) => {
    const [stateChecked, setStateChecked] = useState(checked);

    const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateChecked(e.target.checked);
        onChange(e.target.checked, name);
    };
    return (
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant='h6'>{label}</Typography>
            <FormControlLabel sx={{ m: 0, ...sx }} control={<IOSSwitchStyled sx={{ m: 1 }} defaultChecked={defaultChecked} onChange={onChangeSwitch} checked={stateChecked} />} label={checked ?? stateChecked ? labelSwitch[0] : labelSwitch[1]} />
        </Stack>
    );
};
