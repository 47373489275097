import React, { FC } from 'react';
import { Card, Paper, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { TableHeader } from 'src/UIKit/TableHeader';
import { useNltdevskeletonsTable } from 'src/modules/nltdevskeletons/presenters/useNltdevskeletonsTable';
import { nltdevskeletonsModel } from 'src/modules/nltdevskeletons/entity/nltdevskeletons/NltdevskeletonsModel';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { NltdevskeletonsTableRow } from '../tableRow';

const TABLE_HEAD = ['Image', 'Name', 'Description', 'Slug', 'Parent', 'Status', ''];

export const NltdevskeletonsTable: FC = observer(() => {
    const { t } = useUiContext();
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useNltdevskeletonsTable();

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <ListToolbar search={search} onSearch={onSearch} placeholder={t('nltdevskeletons')} />
            {/* <Scrollbar > */}
            {/* <TableContainer sx={{ position: "relative" }}> */}
                <TableContainer sx={{ height: "calc(100vh - 350px)", position: "relative" }}>
                <Table>
                    <TableHeader headLabels={TABLE_HEAD} />
                    <TableBody>
                        {nltdevskeletonsModel.nltdevskeletons?.rows.map((item) => (<NltdevskeletonsTableRow item={item} key={item.id} />))}
                    </TableBody>
                    {/* <TableEmptyList /> */}
                </Table>
            </TableContainer>
            {/* </Scrollbar> */}
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={nltdevskeletonsModel.nltdevskeletons?.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </Paper>
    );
})
