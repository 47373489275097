import { useEffect } from "react";
import { newsClientService } from "../entity/newsClient/NewsClientService";
import { newsClientModel } from "../entity/newsClient/NewsClientModel";
import { useParams } from "react-router-dom";

export const useNewsCategory = () => {
    const slug = useParams().slug;
    const lastNews = newsClientModel.list;

    useEffect(() => {
        newsClientService.list({ limit: 20, offset: 0, slug: slug });
        return () => {
            newsClientModel.list = null;
        };
    }, []);

    return { lastNews };
}