import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { CategoryImageDropZone } from '../components/categoryImageDropZone';
import { useCategoryEditing } from '../../presenters/useCategoryEditing';
import { TextInput } from 'src/UIKit/TextInput';

export const CategoryEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { category, options, parent, errors, onChange, onUpdateCategory } = useCategoryEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('editCategory')} buttonTitle={t('save')} onPress={onUpdateCategory} />
            <CategoryImageDropZone />
            <Card sx={{ p: 2, mb: 2 }}>
                <TextInput label={t('name')} name='name' onChange={onChange} value={category?.name} error={errors} required />
                <TextInput label={t('description')} name='description' onChange={onChange} value={category?.description} />
                <TextInput label={t('slug')} name='slug' onChange={onChange} value={category?.slug} error={errors} required />
                <Select label={t('parentCategory')} name='parent_id' onChange={onChange} value={parent?.name || ''} options={options} />
            </Card>
        </Container>
    );
})
