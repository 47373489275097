import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { useCandidatesEditing } from '../../presenters/useCandidatesEditing';
import { TextInput } from 'src/UIKit/TextInput';
import { ResourcesView } from 'src/modules/resources/ui/Resources';
import { links } from 'src/Links';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';

export const CandidateEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { candidate, errors, onChange, onUpdate } = useCandidatesEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('candidates.editCandidates')} buttonTitle={t('save')} onPress={onUpdate} />
            <Card sx={{ p: 2, mb: 2 }}>
                <TextInput label={t('first_name')} name='first_name' onChange={onChange} value={candidate?.first_name} error={errors} required />
                <TextInput label={t('last_name')} name='last_name' onChange={onChange} value={candidate?.last_name} error={errors} required />
                <TextInput label={t('email')} name='email' onChange={onChange} value={candidate?.email} />
                <TextInput label={t('phone')} name='phone' onChange={onChange} value={candidate?.phone} error={errors} />
                <TextInput label={t('link')} name='link' onChange={onChange} value={candidate?.link} error={errors} />
                <TextInput label={t('position')} name='position' onChange={onChange} value={candidate?.position} error={errors} />
                <TextInput label={t('skills')} name='skills' onChange={onChange} value={candidate?.skills} error={errors} />
                <TextInput label={t('status')} name='status' onChange={onChange} value={candidate?.status} error={errors} />
                <TextInput label={t('rating')} name='rating' onChange={onChange} value={candidate?.rating} error={errors} />
                <TextInput
                    label={t('comment')}
                    name='comment'
                    onChange={onChange}
                    value={candidate?.comment}
                    error={errors}
                    textFieldProps={{
                        multiline: true,
                        minRows: 5,
                        fullWidth: true
                    }}
                />
            </Card>
        </Container>
    );
})
