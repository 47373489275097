import { toastService } from "src/libraries/toast";
import { getFeaturesUseCase } from "./getFeaturesUseCase";
import { appStateModel } from "src/entities/appState/AppStateModel";
import { featuresService } from "../entity/features/FeaturesService";

export const deleteFeaturesUseCase = async (id: number) => {
    try {
        appStateModel.isLoading = true;
        const response = await featuresService.delete(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            await getFeaturesUseCase();
            toastService.onSuccess('Success');
        }
    } catch (error) {
        console.warn('deleteFeaturesUseCase: ', error);
    } finally {
        appStateModel.isLoading = false;
    }
}   