import { toastService } from "src/libraries/toast";
import { newsCategoryService } from "../entity/newsCategories/NewsCategoryService";
import { newsCategoryModel } from "../entity/newsCategories/NewsCategoryModel";

export const getNewsCategoryUseCase = async (id: number | string) => {
    try {
        const response = await newsCategoryService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            newsCategoryModel.newsCategory = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getNewsCategoryUseCase: ', error);
    }
}  