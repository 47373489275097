import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { useNewsEditing } from '../../presenters/useNewsEditing';
import { TextInput } from 'src/UIKit/TextInput';
import { NewsImageDropZone } from '../components/categoryImageDropZone';
import { ResourcesView } from 'src/modules/resources/ui/Resources';
import { links } from 'src/Links';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';

export const NewsEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { news, errors, onChange, onUpdateNews, onRefresh, parent, options } = useNewsEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('editNews')} buttonTitle={t('save')} onPress={onUpdateNews} />
            <Card sx={{ p: 2, mb: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4.5} md={3.5} lg={3.5}  >
                        <NewsImageDropZone />
                    </Grid>
                    <Grid item xs={12} sm={7.5} md={8.5} lg={8.5}  >
                        <TextInput label={t('name')} name='name' onChange={onChange} value={news?.name} error={errors} required />
                        <TextInput label={t('description')} name='description' onChange={onChange} value={news?.description} />
                        <TextInput label={t('slug')} name='slug' onChange={onChange} value={news?.slug} error={errors} required />
                        <Select label={t('category')} name='category_id' onChange={onChange} value={parent?.name || ''} options={options} />
                        <IOSSwitch label={t('active')} name="available" onChange={onChange} checked={news?.available} />
                    </Grid>
                </Grid>
            </Card>
            <ResourcesView resources={news?.content || []} link={links.news} entityId={news?.id} onUpdate={onRefresh} />
        </Container>
    );
})
