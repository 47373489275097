import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteFeaturesUseCase } from "../useCases/deleteFeaturesUseCase";
import { updateFeaturesUseCase } from "../useCases/updateFeaturesUseCase";
import { IFeatures } from "../entity/features/IFeatures";

export const useFeaturesTableItem = (item: IFeatures) => {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const onOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const onCloseMenu = () => {
        setOpen(null);
    };

    // const onHandleClick = () => {
    //     onPress(item.id);
    // };

    const onDelete = async () => {
        await deleteFeaturesUseCase(item.id);
    };

    const onEdit = () => {
        navigate('/dashboard/features/edit/' + item.id);
    };

    const onActiveSwitch = async () => {
        await updateFeaturesUseCase({ id: item.id, available: !item.available });
    }

    return { open, onActiveSwitch, onOpenMenu, onCloseMenu, onDelete, onEdit }
}