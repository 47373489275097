import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useNltdevskeletons } from '../../presenters/useNltdevskeletons';
import { NltdevskeletonsTable } from '../components/table';

export const NltdevskeletonsView: FC = observer(() => {
    const { t } = useUiContext();
    const { onGoToCreateNltdevskeleton } = useNltdevskeletons();

    return (
        <Container maxWidth="xl" >
            <DashboardHeader title={t('nltdevskeletons')} buttonTitle={t('createNltdevskeleton')} onPress={onGoToCreateNltdevskeleton} />
            <NltdevskeletonsTable />
        </Container>
    );
})
