import { styled } from '@mui/material/styles';

export const StyledDraggedComponent = styled<any>('div')(({ theme, isDragging, marginLeft, index }) => {
    return {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isDragging ? theme.palette.action.hover : theme.palette.background.paper,
    }
});

export const styles = {
    row: {
        paddingRight: 2,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        height: 70,
        borderBottom: '1px solid #cccccc',
    },
    arrowContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: 48,
        height: 48,
        mx: 1
    },
    imageContainer: {
        mr: 2,
        width: '60px',
        height: '60',
        borderRadius: 1.2,
        objectFit: 'contain'
    },
    image: {
        width: '60px',
        height: '60px',
        borderRadius: 1.2,
        objectFit: 'contain',
    }
}