import React, { FC } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { DashboardLayout } from '../layouts/dashboard';
import { AuthorizationView } from '../modules/authentication/ui/Authorization';
import Page404 from '../modules/Page404';
import { RegistrationView } from 'src/modules/authentication/ui/Registration';
import { CategoriesView } from 'src/modules/categories/ui/Categories';
import { CategoryCreatingView } from 'src/modules/categories/ui/CategoryCreating';
import { CategoryEditingView } from 'src/modules/categories/ui/CategoryEditing';
import { NltdevskeletonsView } from 'src/modules/nltdevskeletons/ui/Nltdevskeletons';
import { NltdevskeletonCreatingView } from 'src/modules/nltdevskeletons/ui/NltdevskeletonCreating';
import { NltdevskeletonEditingView } from 'src/modules/nltdevskeletons/ui/NltdevskeletonEditing';
import { Outlet } from 'react-router-dom';
import { NewsView } from 'src/modules/news/ui/News';
import { NewsCreatingView } from 'src/modules/news/ui/NewsCreating';
import { NewsEditingView } from 'src/modules/news/ui/NewsEditing';
import { FeaturesView } from 'src/modules/features/ui/Features';
import { FeaturesCreatingView } from 'src/modules/features/ui/FeaturesCreating';
import { FeaturesEditingView } from 'src/modules/features/ui/FeaturesEditing';
import { NewsCategoriesView } from 'src/modules/newsCategories/ui/NewsCategories';
import { NewsCategoryCreatingView } from 'src/modules/newsCategories/ui/NewsCategoryCreating';
import { NewsCategoryEditingView } from 'src/modules/newsCategories/ui/NewsCategoryEditing';
import { NewsHomeView } from 'src/modules-client/news/ui/news-home';
import { CandidateCreatingView } from 'src/modules/hr-department/candidates/ui/CandidateCreating';
import { CandidateEditingView } from 'src/modules/hr-department/candidates/ui/CandidateEditing';
import { VacanciesView } from 'src/modules/hr-department/vacancies/ui/Vacancies';
import { VacancyCreatingView } from 'src/modules/hr-department/vacancies/ui/VacancyCreating';
import { VacancyEditingView } from 'src/modules/hr-department/vacancies/ui/VacancyEditing';
import { CandidatesView } from 'src/modules/hr-department/candidates/ui/Candidates';
import { NewsCategoryView } from 'src/modules-client/news/ui/news-category';
import { NewsClientView } from 'src/modules-client/news/ui/news';
import { NewsLayout } from 'src/modules-client/news/ui/layouts';
import { IssuesView } from 'src/modules/issues/ui/Issues';
import { PublicRoutes } from './publicRoutes';
import { PrivateRoutes } from './privateRoutes';
import { SlateView } from 'src/modules/slate';

export const Router: FC = () => {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoutes><DashboardLayout /></PrivateRoutes>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },

        { path: 'categories', element: <CategoriesView />, },
        { path: 'categories/create', element: <CategoryCreatingView />, },
        { path: 'categories/edit/:id', element: <CategoryEditingView />, },

        { path: 'nltdevskeletons', element: <NltdevskeletonsView />, },
        { path: 'nltdevskeletons/create', element: <NltdevskeletonCreatingView />, },
        { path: 'nltdevskeletons/edit/:id', element: <NltdevskeletonEditingView />, },

        { path: 'news', element: <NewsView />, },
        { path: 'news/create', element: <NewsCreatingView />, },
        { path: 'news/edit/:id', element: <NewsEditingView />, },

        { path: 'news-categories', element: <NewsCategoriesView />, },
        { path: 'news-categories/create', element: <NewsCategoryCreatingView />, },
        { path: 'news-categories/edit/:id', element: <NewsCategoryEditingView />, },

        { path: 'features', element: <FeaturesView />, },
        { path: 'features/create', element: <FeaturesCreatingView />, },
        { path: 'features/edit/:id', element: <FeaturesEditingView />, },

        { path: 'candidates', element: <CandidatesView />, },
        { path: 'candidates/create', element: <CandidateCreatingView />, },
        { path: 'candidates/edit/:id', element: <CandidateEditingView />, },

        { path: 'vacancies', element: <VacanciesView />, },
        { path: 'vacancies/create', element: <VacancyCreatingView />, },
        { path: 'vacancies/edit/:id', element: <VacancyEditingView />, },

        { path: 'issues', element: <IssuesView />, },

      ],
    },
    { path: 'login', element: <PublicRoutes><AuthorizationView /></PublicRoutes>, },
    { path: 'registration', element: <RegistrationView />, },
    {
      path: '/news',
      element: <NewsLayout />,
      children: [
        { element: <Navigate to="/news/home" />, index: true },
        { path: 'home', element: <NewsHomeView />, },
        { path: 'category/:slug', element: <NewsCategoryView />, },
        { path: 'articles/:slug', element: <NewsClientView />, },
      ]
    },
    {
      element: <Outlet />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
    { path: '/slate', element: <PublicRoutes><SlateView /></PublicRoutes>, },
  ]);

  return routes;
};
