import { Typography } from "@mui/material";
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from "react";
import { issuesModel } from "src/modules/issues/entity/issues/IssuesModel";
import { useIssuesStatistics } from "src/modules/issues/presenters/useIssuesStatistics";
import { useUiContext } from "src/UIProvider";

export const IssuesStatistics: FC = observer(() => {
    const { t } = useUiContext();
    const {
        reopened, reopenedPercentage,
        notEstimated, notEstimatedPercentage,
        estimationPercentage, biggestEstimationDiscrepancy, biggestAggregatetimespen,
        total
    } = useIssuesStatistics();

    return (
        <div className="flex flex-row" >
            <Typography variant="h4" sx={{ ml: 2, mt: 2 }} gutterBottom>Issues</Typography>
            <div className="flex w-44 min-w-44 justify-center h-20 m-4 flex-col" >
                <span className="m-2" >{t('reopened')}: {reopened}</span>
                <span className="m-2" >{t('percentage')}: {reopenedPercentage.toFixed(2)}%</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center h-20 m-4 flex-col" >
                <span className="m-2" >{t('notEstimated')}: {notEstimated}</span>
                <span className="m-2" >{t('percentage')}: {notEstimatedPercentage.toFixed(2)}%</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center h-20 m-4 flex-col" >
                <span className="m-2" >{t('biggestEstimationDiscrepancy')}: {biggestEstimationDiscrepancy}</span>
                <span className="m-2" >{t('estimation')}: {estimationPercentage.toFixed(2)}%</span>
            </div>
            <div className="flex w-44 min-w-44 justify-center h-20 m-4 flex-col" >
                <span className="m-2" >{t('biggestAggregatetimespen')}: {biggestAggregatetimespen?.aggregatetimespent}</span>
                <span className="m-2" >{t('issue_key')}: {biggestAggregatetimespen?.jira_issue_key}</span>
            </div>
        </div>
    );
});
