import React, { FC } from 'react';
import { Card, Stack } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { TextInput } from 'src/UIKit/TextInput';
import { useResourceCreating } from '../../presenters/useResourceCreating';
import { ResourceItem } from '../components/resourceItem';
import { ResourceControls } from '../components/resourceControls';
import { IResources } from '../../entity/resources/IResources';

interface IProps {
    item?: IResources;
    link: string;
    entityId: number;
    close: () => void;
    onUpdate?: () => Promise<void>;
}

export const ResourcesCreatingView: FC<IProps> = observer(({ item, link, entityId, onUpdate, close }) => {
    const { t } = useUiContext();
    const { resource, errors, onChange, TYPES, typeObject, onCreateResource, isLoading } = useResourceCreating(link, entityId, close, item, onUpdate);

    return (
        <Card sx={{ p: 2, mb: 2 }}>
            <Stack spacing={1}>
                <Select label={t('resourceType')} name='type' onChange={onChange} value={typeObject?.name || ''} options={TYPES} />
                <TextInput label={t('name')} name='name' onChange={onChange} value={resource?.name} error={errors} />
                <ResourceItem item={resource} onChange={onChange} />
                <ResourceControls onClose={close} onSave={onCreateResource} isLoading={isLoading}/>
            </Stack>
        </Card>
    );
})
