import { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { ICandidates  } from "../entity/candidates/ICandidates";
import { candidatesService } from "../entity/candidates/CandidatesService";

export const useCandidatesTableItem = (item: ICandidates) => {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const onOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const onCloseMenu = () => {
        setOpen(null);
    };

    // const onHandleClick = () => {
    //     onPress(item.id);
    // };

    const onDelete = async () => {
        await candidatesService.delete(item.id);
    };

    const onEdit = () => {
        navigate('/dashboard/candidates/edit/' + item.id);
    };

    const onActiveSwitch = async () => {
        // await candidatesService.update({ id: item.id, available: !item.available });
    }

    return { open, onActiveSwitch, onOpenMenu, onCloseMenu, onDelete, onEdit }
}