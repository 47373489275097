import React, { FC } from 'react';
import { Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { NewsHomeHeader } from '../components/newsHomeHeader';
import { PostCard } from '../components/post';
import { useNewsCategory } from '../../presenters/useNewsCategory';

export const NewsCategoryView: FC = observer(() => {
    const { t } = useUiContext();
    const { lastNews } = useNewsCategory();

    return (
        <Container maxWidth="lg">
            <Grid container spacing={4} mt={4}>
                {lastNews?.rows.map((item) => (<PostCard key={item.id} item={item} />))}
            </Grid>
        </Container>
    );
})
