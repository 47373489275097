import React, { FC } from 'react';
import { Container } from '@mui/material';
import { observer } from 'mobx-react';
import { StyledContent } from './styles';
import { RegistrationForm } from '../components/registrationForm';
import { RegistrationSignIn } from '../components/registrationSignIn';

export const RegistrationView: FC = observer(() => {

    return (
        <Container maxWidth="sm">
            <StyledContent>
                <RegistrationSignIn />
                <RegistrationForm />
            </StyledContent>
        </Container>
    );
})
