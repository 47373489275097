class Config {
    public audience = ['ALL', 'USER_ID', 'CITY', 'LANGUAGE', 'FMS_TOKEN', 'PLATFORM'];
    public audienceCondition = ['IS_EQUAL', 'IS_NOT_EQUAL'];
    private _emailRegex: RegExp = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

    public get emailRegex(): RegExp {
        return this._emailRegex;
    }

}

export const config = new Config();