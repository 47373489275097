import React, { FC } from "react";
import { Box, Typography, Link } from "@mui/material";
import { useUiContext } from "src/UIProvider";
import { useNavigate } from "react-router-dom";

export const AuthSignUp: FC = () => {
    const { t } = useUiContext();
    const navigate = useNavigate();

    const onGoToSignUp = () => {
        navigate('/registration');
    };

    return (
        <Box>
            <Typography variant="h4" gutterBottom>{t('signInTo')} {t('appName')}</Typography>
            <Typography variant="body2" sx={{ mb: 4 }}>
                {t('doNotHaveAnAccount')} {''}
                <Link sx={{ cursor: 'pointer' }} variant="subtitle2" onClick={onGoToSignUp}>{t('getStarted')}</Link>
            </Typography>
        </Box>
    );
};
