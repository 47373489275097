import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useVacancies } from '../../presenters/useVacancies';
import { VacanciesTable } from '../components/table';

export const VacanciesView: FC = observer(() => {
    const { t } = useUiContext();
    const { onGoToCreateEntity } = useVacancies();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('vacancies.vacancies')} buttonTitle={t('vacancies.createVacancy')} onPress={onGoToCreateEntity} />
            <VacanciesTable />
        </Container>
    );
})
