export const fr = {
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    email: 'Email',
    phone: 'Téléphone',
    link: 'Lien',
    position: 'Poste',
    skills: 'Compétences',
    status: 'Statut',
    rating: 'Évaluation',
    comment: 'Commentaire',
    sortNumber: 'Numéro de tri',
    file: 'Fichier',
    close: 'Fermer',
    slug: 'Slug',
    price: 'Prix',
    currencyName: 'Nom de la devise',
    currencyShortName: 'Abréviation de la devise',
    currencies: 'Devises',
    addCurrency: 'Ajouter une devise',
    languageName: 'Nom de la langue',
    languageShortName: 'Abréviation de la langue',
    addLanguage: 'Ajouter une langue',
    languages: 'Langues',
    productImages: 'Images du produit',
    newProduct: 'Nouveau produit',
    productCategory: 'Catégorie de produit',
    products: 'Produits',
    createProduct: 'Créer un produit',
    editProduct: 'Modifier le produit',
    editCategory: 'Modifier la catégorie',
    save: 'Enregistrer',
    categoryIcon: 'Icône de catégorie',
    imageFormats: '(formats svg, png, jpg ou jpeg, taille max 1 Mo)',
    pressForAddFileOrDropHere: 'Cliquez pour ajouter un fichier ou déposez-le ici',
    active: 'Actif',
    inactive: 'Inactif',
    none: 'Aucun',
    parentCategory: 'Catégorie parente',
    description: 'Description',
    name: 'Nom',
    newCategory: 'Nouvelle catégorie',
    createCategory: 'Créer une catégorie',
    categories: 'Catégories',
    statistics: 'Statistiques',
    totalSend: 'Total envoyé',
    success: 'Succès',
    failed: 'Échoué',
    notificationName: 'Nom de la notification',
    editNotification: 'Modifier la notification',
    updateNotification: 'Mettre à jour la notification',
    cancel: 'Annuler',
    add: 'Ajouter',
    owner: 'Propriétaire',
    admin: 'Administrateur',
    marketing: 'Marketing',
    developer: 'Développeur',
    role: 'Rôle',
    addUserByEmail: 'Ajouter un utilisateur par email',
    users: 'Utilisateurs',
    addUser: 'Ajouter un utilisateur',
    usersAndPermissions: 'Utilisateurs et permissions',
    apiKey: 'Clé API',
    copiedToClipboard: 'Copié dans le presse-papiers',
    appName: 'NLTDEV',
    signInTo: 'Se connecter à',
    getStarted: 'Commencer',
    doNotHaveAnAccount: 'Vous n’avez pas de compte ?',
    or: 'ou',
    emailAddress: 'Adresse email',
    password: 'Mot de passe',
    login: 'Connexion',
    forgotPassword: 'Mot de passe oublié ?',
    yourNotificationServiceProjects: 'Vos projets de service de notification',
    addProject: 'Ajouter un projet',
    projectName: 'Nom du projet',
    create: 'Créer',
    firebaseCredentials: 'Identifiants Firebase',
    generateFirebaseCredentials: 'Générer des identifiants Firebase avec les étapes suivantes',
    uploadFile: 'Téléverser un fichier',
    yourCredentialsAdded: 'Vos identifiants ont été ajoutés',
    yourCredentialsNotAdded: 'Vos identifiants n’ont pas été ajoutés',
    notifications: 'Notifications',
    createNotification: 'Créer une notification',
    newNotification: 'Nouvelle notification',
    title: 'Titre',
    text: 'Texte',
    enterTitle: 'Entrez le titre',
    yourMessage: 'Votre message',
    jsonData: 'Données JSON',
    jsonDataPlaceholder: '{}',
    audience: 'Audience',
    searchNotifications: 'Rechercher des notifications...',
    edit: 'Modifier',
    delete: 'Supprimer',
    imageUrl: 'URL de l’image',
    imageUrlPlaceholder: 'https://monimage.png',
    send: 'Envoyer',
    edited: 'Modifié',
    by: 'par',
    group: 'Groupe',
    condition: 'Condition',
    value: 'Valeur',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    confirmPassword: 'Confirmer le mot de passe',
    signUp: 'Inscription',
    alreadyHaveAccount: 'Vous avez déjà un compte ?',
    signIn: 'Connexion',
    signUpTo: 'Inscription à',
    audienceCondition: 'Condition d’audience',
    audienceValue: 'Valeur d’audience',
    category: 'Catégorie',

    nltdevskeletonIcon: 'Icône de squelette NLTDEV',
    nltdevskeletons: 'Squelettes NLTDEV',
    nltdevskeleton: 'Squelette NLTDEV',
    createNltdevskeleton: 'Créer un squelette NLTDEV',
    editNltdevskeleton: 'Modifier le squelette NLTDEV',

    newsCategoryIcon: 'Icône de catégorie de nouvelles NLTDEV',
    newsCategories: 'Catégorie de nouvelles',
    createNewsCategory: 'Créer une catégorie de nouvelles',
    editNewsCategory: 'Modifier la catégorie de nouvelles',

    newsIcon: 'Icône de nouvelles NLTDEV',
    news: 'Nouvelles',
    createNews: 'Créer une nouvelle',
    editNews: 'Modifier la nouvelle',

    featuresIcon: 'Icône de fonctionnalités NLTDEV',
    features: 'Fonctionnalités',
    createFeatures: 'Créer une fonctionnalité',
    editFeature: 'Modifier la fonctionnalité',

    resources: 'Ressources',
    createResource: 'Créer une ressource',
    editResource: 'Modifier la ressource',
    resource: 'Ressource',
    resourceType: 'Type de ressource',

    editFeatures: 'Modifier les fonctionnalités',
    estimate: 'Estimer',
    estimateMarket: 'Estimer le marché',
    developmentSide: 'Côté développement',
    frontEnd: 'Front-end',
    backEnd: 'Back-end',
    mobile: 'Mobile',
    markdown: 'Markdown',

    candidates: {
        candidates: 'Candidats',
        createCandidate: 'Créer un candidat',
        editCandidate: 'Modifier le candidat',
        candidate: 'Candidat',
    },
    vacancies: {
        vacancies: 'Offres d’emploi',
        createVacancy: 'Créer une offre d’emploi',
        editVacancy: 'Modifier l’offre d’emploi',
        vacancy: 'Offre d’emploi',
    },
    requirements: 'Exigences',
    benefits: 'Avantages',
    salary: 'Salaire',
    applicationDeadline: 'Date limite de candidature',
    available: 'Disponible',
    employer: 'Employeur',

    reopened: 'Reopened',
    timeoriginalestimate: 'Estimate',
    aggregatetimespent: 'Time spent',
    assignee: 'Assignee',
    project_name: 'Project name',
    summary: 'Summary',
    issue_key: 'Issue key',
    notEstimated: 'Not estimated',
    percentage: 'Percentage',

};