import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { TextInput } from 'src/UIKit/TextInput';
import { useNltdevskeletonCreating } from '../../presenters/useNltdevskeletonCreating';
import { NltdevskeletonImageDropZone } from '../components/categoryImageDropZone';

export const NltdevskeletonCreatingView: FC = observer(() => {
    const { t } = useUiContext();
    const { nltdevskeleton, parent, errors, options, onChange, onCreateNltdevskeleton } = useNltdevskeletonCreating();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('editNltdevskeleton')} buttonTitle={t('save')} onPress={onCreateNltdevskeleton} />
            <NltdevskeletonImageDropZone />
            <Card sx={{ p: 2, mb: 2 }}>
                <TextInput label={t('name')} name='name' onChange={onChange} value={nltdevskeleton?.name} error={errors} required />
                <TextInput label={t('description')} name='description' onChange={onChange} value={nltdevskeleton?.description} />
                <TextInput label={t('slug')} name='slug' onChange={onChange} value={nltdevskeleton?.slug} error={errors} required />
                <Select label={t('parentCategory')} name='parent_id' onChange={onChange} value={parent?.name || ''} options={options} />
            </Card>
        </Container>
    );
})
