import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { observer } from 'mobx-react';

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export const DashboardProjectHeader: FC = observer(({ }) => {
    return (
        <Box sx={{ my: 5, mx: 2.5 }}>
            <Link underline="none">
                <StyledAccount>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            Skeleton
                        </Typography>
                    </Box>
                </StyledAccount>
            </Link>
        </Box>
    );
});
