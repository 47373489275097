import { useEffect, useState } from "react";
import { issuesService } from "../entity/issues/IssuesService";
import { issuesModel } from "../entity/issues/IssuesModel";

const TODAY = new Date().toISOString().split('T')[0];

export const useIssues = () => {
    const [filters, setFilters] = useState({ jira_project_key: '', assignee: '', summary: '', time_from: TODAY, time_to: TODAY, });
    const issues = issuesModel.all;

    useEffect(() => {
        issuesService.getFilters();
    }, []);

    useEffect(() => {
        const body: any = {};
        if (filters.jira_project_key) body.jira_project_key = filters.jira_project_key;
        if (filters.assignee) body.assignee = filters.assignee;
        if (filters.summary) body.summary = filters.summary;
        if (filters.time_from) body.time_from = new Date(filters.time_from).getTime();
        if (filters.time_to) body.time_to = new Date(filters.time_to).getTime();
        issuesService.all(body);
    }, [filters]);

    return { issues, filters, setFilters };
}
