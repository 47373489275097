import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import { TextEditor } from 'src/UIKit/TextEditor';

interface IProps {
    item: IResources;
    onChange: (value: string, field: string) => void;
}

export const ResourceHTML: FC<IProps> = observer(({ item, onChange }) => {
    const { t } = useUiContext();

    return (
        <Box >
            <TextEditor value={item.value} onChange={(value) => { onChange(value, 'value') }} />
        </Box>
    );
})
