import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteNewsUseCase } from "../useCases/deleteNewsUseCase";
import { updateNewsUseCase } from "../useCases/updateNewsUseCase";
import { INews } from "../entity/news/INews";

export const useNewsTableItem = (item: INews) => {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const onOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const onCloseMenu = () => {
        setOpen(null);
    };

    // const onHandleClick = () => {
    //     onPress(item.id);
    // };

    const onDelete = async () => {
        await deleteNewsUseCase(item.id);
    };

    const onEdit = () => {
        navigate('/dashboard/news/edit/' + item.id);
    };

    const onActiveSwitch = async () => {
        await updateNewsUseCase({ id: item.id, available: !item.available });
    }

    return { open, onActiveSwitch, onOpenMenu, onCloseMenu, onDelete, onEdit }
}