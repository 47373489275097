import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorizationUseCase } from '../useCases/authorizationUseCase';
import { z } from 'zod';

const errorsSchema = z.object({
    login: z.string().min(1, { message: "Not empty" }),
    password: z.string().min(6, { message: "Not empty" }),
});

export const useAuthorization = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ login: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            setLogin('');
            setPassword('');
            setIsLoading(false);
            setShowPassword(false);
        }
    }, []);

    const onLogin = async () => {
        setIsLoading(true);
        const result: any = errorsSchema.safeParse({ login, password });

        if (result.success) {
            const response = await authorizationUseCase({ login, password });
            if (response?.isError) {
                setErrors((prev: any) => ({ ...prev, password: response.message }));
            } else {
                navigate('/dashboard/categories'), { replace: true };
            }
        } else {
            result.error.errors.map((item: any) => { setErrors((prev) => ({ ...prev, [item.path[0]]: item.message })); });
        }
        setIsLoading(false);
    };

    const onShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const onChangLogin = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLogin(e.target.value);
        setErrors((prev) => ({ ...prev, login: '' }));
    };

    const onChangPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassword(e.target.value);
        setErrors((prev) => ({ ...prev, password: '' }));
    };

    return { isLoading, errors, showPassword, login, password, onLogin, onShowPassword, onChangLogin, onChangPassword, };
};
