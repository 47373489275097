import React, { FC, memo } from 'react';
import { Box, Typography, TextField, TextFieldProps, TypographyProps, BoxProps, FormControl } from '@mui/material';

interface IProps {
    value?: string | number | null;
    name: string;
    onChange: (value: string, name: string) => void;
    required?: boolean;
    error?: { [key: string]: string };
    label?: string;
    textFieldProps?: TextFieldProps;
    typographyProps?: TypographyProps;
    containerProps?: BoxProps;
}

export const TextInput: FC<IProps> = memo(({ label, name, value = '', onChange, error, required, textFieldProps, typographyProps, containerProps }) => {

    const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value, name);
    }

    return (
        <FormControl fullWidth>
            <Typography variant='h6' mb={1} {...(typographyProps || {})}>{label}{required ? '*' : ''}</Typography>
            <TextField size='small' error={!!error?.[name]} value={value || ''} onChange={onHandleChange} {...(textFieldProps || {})} />
            <Typography minHeight={1} variant='caption' color={'red'} {...(typographyProps || {})}>{error?.[name]}</Typography>
        </FormControl>
    )
});
