import { MobXRepository } from "../../../../repository/MobXRepository"
import { IFeatures } from "./IFeatures";

export interface IFeaturesModel {
    features: { count: number; rows: IFeatures[] } | null;
    feature: IFeatures | null;
}

class FeaturesModel implements IFeaturesModel {
    private featuresRepository = new MobXRepository<{ count: number; rows: IFeatures[] } | null>(null);
    private featureRepository = new MobXRepository<IFeatures | null>(null);

    get features() {
        return this.featuresRepository.data;
    }

    set features(data: { count: number; rows: IFeatures[] } | null) {
        this.featuresRepository.save(data);
    }

    get feature() {
        return this.featureRepository.data;
    }

    set feature(data: IFeatures | null) {
        this.featureRepository.save(data);
    }

    clear = () => {
        this.features = null;
        this.feature = null;
    }

}

export const featuresModel = new FeaturesModel();
