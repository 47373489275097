import { useEffect } from "react";
import { newsCategoryClientModel } from "../entity/newsCategoriesClient/NewsCategoryClientModel";
import { newsCategoryClientService } from "../entity/newsCategoriesClient/NewsCategoryClientService";

export const useNewsLayout = () => {
    const categories = newsCategoryClientModel.all;

    useEffect(() => {
        newsCategoryClientService.all();
    }, []);

    return { categories };
}